import { getColumnByNameAndTableName } from "lib/utils";
import { TableColumnType } from "types/baTypes";
import { RecordItem } from "types/common";
import { ExtendedSchema } from "./schema";
import { NOTES_LINKS_TABLE, USER_TABLE_NAME } from "./constants";

export const getColumnsForNotes = (finalColumnOptions: TableColumnType[]) => {
  const nameColumn = getColumnByNameAndTableName(finalColumnOptions, "name", "notes");
  const messageColumn = getColumnByNameAndTableName(finalColumnOptions, "body_html", "notes");
  const fileColumn = getColumnByNameAndTableName(finalColumnOptions, "", "notes_files");
  const createdByColumn = getColumnByNameAndTableName(finalColumnOptions, "full_name", USER_TABLE_NAME);
  const createdAtColumn = getColumnByNameAndTableName(finalColumnOptions, "created_at", "notes");
  const xyCoordinateColumn = getColumnByNameAndTableName(finalColumnOptions, "", "files_annotations");

  const finalColumns: RecordItem = {};

  if (nameColumn) {
    finalColumns["name"] = nameColumn;
  }

  if (messageColumn) {
    finalColumns["body_html"] = messageColumn;
  }

  if (fileColumn) {
    finalColumns["files"] = fileColumn;
  }

  if (createdByColumn) {
    finalColumns["createdBy"] = createdByColumn;
  }

  if (createdAtColumn) {
    finalColumns["createdAt"] = createdAtColumn;
  }

  if (xyCoordinateColumn) {
    finalColumns["xyCoordinate"] = xyCoordinateColumn;
  }

  return finalColumns;
};

export const getNotesLinkForeignKey = (parentTableName: string, extendedSchema: ExtendedSchema) => {
  const noteLinksTableProps = extendedSchema?.[NOTES_LINKS_TABLE];
  const noteLinkFK = noteLinksTableProps?.fk?.find(
    (fkItem) =>
      fkItem.table === parentTableName &&
      (parentTableName !== USER_TABLE_NAME || !["created_by", "updated_by"].includes(fkItem.attributeId))
  );

  return noteLinkFK;
};
