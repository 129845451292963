import cloneDeep from "lodash/cloneDeep";
import startCase from "lodash/startCase";
import toUpper from "lodash/toUpper";
import uniq from "lodash/uniq";
import { v4 } from "uuid";
import { ApiPageConfig, ApiRecordType, TableColumnTemplate, TableFilterTemplate } from "types/apiTypes";
import {
  AlgoliaSearchTable,
  ColumnErrorMsg,
  Page,
  TableColumnType,
  TableFilterType,
  TableViewSection,
  TableViewType
} from "types/baTypes";
import { COLUMN_CONFIG_ERROR_TYPES, GridViewColumn, PAGE_ERROR_TYPES, RecordItem, SelectOptions } from "types/common";

import { ExtendedSchema, TableSchema } from "utils/schema";

import {
  ActionType,
  APP_FILTER_TYPES,
  CellType,
  FILTER_OPERATOR,
  LookupTypes,
  emptySortOrder,
  RELATION_TYPES,
  GENERIC_CELL_LAYOUTS
} from "utils/constants";
import { getColumnLookupLabel } from "utils/dataUtils";
import { checkColumnValidity } from "utils/columnUtils";
import { getNotesLinkForeignKey } from "utils/notesUtils";

export const getFormatFromDBFormat = (dbFormat: string) => {
  switch (dbFormat) {
    case "timestamp with time zone":
      return "datetime";
    case "real":
      return "float";
    default:
      return dbFormat;
  }
};

export const getColumnDBType = (
  columnName: string,
  tableName?: string,
  extendedSchema?: { [key: string]: TableSchema }
) => {
  if (!tableName || !extendedSchema?.[tableName]) {
    return;
  }
  const tableProps = extendedSchema?.[tableName];
  if (tableProps?.properties?.[columnName]) {
    let type = tableProps.properties[columnName].type;
    if (tableProps.pk === columnName || tableProps.compositePk?.map((key) => key.attributeId).includes(columnName)) {
      type = "id";
    }
    return {
      isFKey: !!tableProps.fk.find((fKey) => fKey.attributeId === columnName),
      type,
      format: getFormatFromDBFormat(tableProps.properties[columnName].format)
    };
  }
  return;
};

export const addLabelsToDuplicateLookupColumns = (
  columns: TableColumnType[],
  extendedSchema?: { [key: string]: TableSchema },
  parentTablename?: string
) => {
  // Check for multiple references to same table
  let updatedColumns = cloneDeep(columns || []);
  const currentLookupTableNames: Record<string, Array<TableColumnType>> = {};
  const lookupLabelsByTableName: Record<string, string[]> = {};
  // const level0LookupTables: string[] = [];
  (updatedColumns || []).forEach((col: TableColumnType, colIdx: number) => {
    if (col.isLookup) {
      const { lookupPath } = col;
      const firstLevelTableDets = lookupPath?.["0"];
      if (firstLevelTableDets?.lookupTableName) {
        const tableName = firstLevelTableDets?.lookupForeignKey || firstLevelTableDets.lookupTableName;
        if (!currentLookupTableNames[tableName]) {
          currentLookupTableNames[tableName] = [];
          lookupLabelsByTableName[tableName] = [];
        }
        // Keep existing label if present (we do a duplicate check later)
        if (firstLevelTableDets?.lookupColumnLabel) {
          lookupLabelsByTableName[tableName].push(firstLevelTableDets?.lookupColumnLabel);
        } else {
          lookupLabelsByTableName[tableName].push(`${tableName}_${colIdx}`);
        }
        let finalCol = col;
        if (currentLookupTableNames[tableName]?.length >= 1) {
          finalCol = {
            ...col,
            lookupPath: {
              ...col.lookupPath,
              "0": {
                ...firstLevelTableDets,
                lookupColumnLabel: firstLevelTableDets?.lookupColumnLabel || `${tableName}_${colIdx}`
              }
            }
          };

          if (
            currentLookupTableNames[tableName].length === 1 &&
            currentLookupTableNames[tableName][0].lookupPath?.["0"]
          ) {
            currentLookupTableNames[tableName][0] = {
              ...currentLookupTableNames[tableName][0],
              lookupPath: {
                ...currentLookupTableNames[tableName][0].lookupPath,
                "0": {
                  ...currentLookupTableNames[tableName][0].lookupPath?.["0"],
                  lookupTableName: currentLookupTableNames[tableName][0].lookupPath?.["0"]?.lookupTableName || "",
                  lookupColumnLabel: `${tableName}_0`
                }
              }
            };
            // Check if lookup filter is present add label to that as well
            if (currentLookupTableNames[tableName][0]?.lookupFilters?.length) {
              currentLookupTableNames[tableName][0] = {
                ...currentLookupTableNames[tableName][0],
                lookupFilters: currentLookupTableNames[tableName][0]?.lookupFilters?.map((filter) => {
                  if (filter.filterLookupPath?.["0"]?.lookupTableName) {
                    return {
                      ...filter,
                      filterLookupPath: {
                        ...filter.filterLookupPath,
                        "0": {
                          ...filter.filterLookupPath?.["0"],
                          lookupColumnLabel: `${tableName}_0`
                        }
                      }
                    };
                  }
                  return filter;
                })
              };
            }
          }
        }

        currentLookupTableNames[tableName].push(finalCol);
      }
    } else if (col.name) {
      col.dbType = getColumnDBType(col.name, parentTablename, extendedSchema);
    }
  });
  Object.keys(currentLookupTableNames).forEach((tableName) => {
    if (currentLookupTableNames[tableName].length > 1) {
      currentLookupTableNames[tableName].forEach((col) => {
        updatedColumns = updatedColumns.map((currentCol) => {
          if (currentCol.id === col.id) {
            return {
              ...col
            };
          }
          return currentCol;
        });
      });
    }
  });

  return updatedColumns.map((col: TableColumnType, colIdx: number) => {
    if (col.isLookup) {
      const { lookupPath } = col;
      const updatedLookupPath: RecordItem = {};
      let level0NewLabel = "";
      // Go to lookupColumns level and add dbType
      Object.keys(lookupPath || {}).forEach((level) => {
        if (level === "0") {
          const level0Lookup = lookupPath?.[level];
          if (level0Lookup) {
            const tableName = level0Lookup?.lookupForeignKey || level0Lookup.lookupTableName;
            if (lookupLabelsByTableName[tableName]?.length > 1) {
              // Check if labels are duplicate
              const labelCount = lookupLabelsByTableName[tableName].filter(
                (label) => label === level0Lookup?.lookupColumnLabel
              ).length;
              if (labelCount > 1) {
                let isFirstLabelReplaced = false;
                const newLabel = `${tableName}_${colIdx}_${Math.floor(Math.random() * 200) + 1}`;
                // Replace first entry of the label with the new one so next check is accurate
                lookupLabelsByTableName[tableName] = lookupLabelsByTableName[tableName].map((label) => {
                  if (!isFirstLabelReplaced && label === level0Lookup.lookupColumnLabel) {
                    isFirstLabelReplaced = true;
                    return newLabel;
                  }
                  return label;
                });
                level0NewLabel = newLabel;
              }
            }
          }
        }
        const updatedLookupPathLevel = { ...(lookupPath?.[level] || {}) };
        if (updatedLookupPathLevel?.lookupColumns) {
          updatedLookupPathLevel?.lookupColumns?.forEach((colName) => {
            if (!updatedLookupPathLevel?.lookupDBType) {
              updatedLookupPathLevel.lookupDBType = {};
            }
            if (updatedLookupPathLevel.lookupDBType) {
              const dbType = getColumnDBType(colName, lookupPath?.[level]?.lookupTableName, extendedSchema);
              updatedLookupPathLevel.lookupDBType[colName] = dbType;
            }
          });
        }
        if (level === "0" && level0NewLabel) {
          updatedLookupPathLevel.lookupColumnLabel = level0NewLabel;
        }
        updatedLookupPath[level] = updatedLookupPathLevel;
      });
      col.lookupPath = updatedLookupPath;
    }
    return col;
  });
};

export const getLookupOptionsForColumn = (
  column: TableColumnType,
  extendedSchema: { [tablename: string]: TableSchema },
  baseTableName?: string
) => {
  const finalLookupColumns: SelectOptions = [];
  const { lookupPath } = column;

  if (!lookupPath) {
    return [];
  }
  const firstLevelLookup = lookupPath[0];
  const tableProps = extendedSchema[firstLevelLookup?.lookupTableName || ""];
  const pageTableProps = extendedSchema[baseTableName || ""];

  if (tableProps?.compositePk?.length) {
    const compositePKAttributes: string[] = [];
    tableProps.compositePk.forEach((pk) => {
      compositePKAttributes.push(pk.attributeId);
      finalLookupColumns.push({
        value: pk.attributeId,
        title: pk.attributeId,
        record: {
          tableName: firstLevelLookup?.lookupTableName,
          isCompositePk: true,
          dbType: tableProps?.properties?.[pk.attributeId]?.format
        }
      });
    });
    // Add any foreign keys in join table
    tableProps.fk?.forEach((fk) => {
      if (!compositePKAttributes.includes(fk.attributeId)) {
        finalLookupColumns.push({
          value: fk.attributeId,
          title: fk.attributeId + " (Foreign Key)",
          record: {
            tableName: firstLevelLookup?.lookupTableName,
            isForeignKey: true,
            dbType: tableProps?.properties?.[fk.attributeId]?.format
          }
        });
      }
    });
  }
  // If lookuptable is a foreign key add ID to list of columns
  if (firstLevelLookup?.lookupForeignKey) {
    finalLookupColumns.push({
      value: firstLevelLookup.lookupForeignKey,
      title: firstLevelLookup.lookupForeignKey + " (Foreign Key)",
      record: {
        tableName: firstLevelLookup?.lookupTableName,
        isForeignKey: true,
        dbType: pageTableProps?.properties?.[firstLevelLookup.lookupForeignKey]?.format
      }
    });
  }
  // Get the last level lookup columns
  const lookupLevels = Object.keys(lookupPath || {});
  const lastLevel = lookupPath[lookupLevels[lookupLevels.length - 1]];
  if (lastLevel?.lookupColumns?.length) {
    lastLevel.lookupColumns.forEach((lc) =>
      finalLookupColumns.push({
        value: lc,
        title: lc,
        record: {
          tableName: lastLevel.lookupTableName,
          dbType: extendedSchema?.[lastLevel.lookupTableName]?.properties?.[lc]?.format
        }
      })
    );
  }
  return finalLookupColumns;
};

export const getSourceTableColumnOptions = (
  sourceColumn: TableColumnType,
  extendedSchema: { [tablename: string]: TableSchema }
) => {
  const { lookupPath } = sourceColumn;
  if (!lookupPath?.[0]?.lookupTableName) return [];
  const tableProps = extendedSchema?.[lookupPath[0].lookupTableName];
  if (!tableProps) return [];
  return tableProps.attributeIds.map((columnName) => ({
    value: columnName,
    title: columnName
  }));
};

export const checkPageValidity = ({
  columns,
  pageTable,
  extendedSchema,
  includeColDetails,
  recordTypesData,
  pageId,
  pageData,
  fixConfigErrors = false
}: {
  columns: TableColumnType[];
  pageTable: string;
  extendedSchema?: { [key: string]: TableSchema };
  pageFilters?: TableViewType;
  searchTable?: AlgoliaSearchTable;
  includeColDetails?: boolean;
  recordTypesData?: ApiRecordType[];
  pageId?: string;
  pageData?: Page;
  fixConfigErrors?: boolean; // When true page config errors are corrected
}): {
  isValid: boolean;
  brokenColumns: string[];
  errorsByColumn: { [colId: string]: Array<ColumnErrorMsg> };
  pageConfigErrors?: string[];
  updatedPageConfig?: ApiPageConfig;
} => {
  if (!columns?.length || !extendedSchema) {
    return { isValid: true, brokenColumns: [], errorsByColumn: {} };
  }

  const tableProps = extendedSchema[pageTable];
  if (!tableProps) {
    return {
      isValid: true,
      brokenColumns: [],
      errorsByColumn: {
        page: [
          {
            message: "Page table not found",
            errorType: PAGE_ERROR_TYPES.INVALID_PAGE_TABLE
          }
        ]
      }
    };
  }
  let isValid = true;
  let brokenColumns: string[] = [];
  const errorsByColumn: { [colId: string]: Array<ColumnErrorMsg> } = {};
  const pageConfigErrors: string[] = [];
  for (const col of columns) {
    const { isColValid, message, errorType } = checkColumnValidity({
      column: col,
      recordTypesData,
      page: {
        id: pageId || "",
        table_name: pageTable,
        path: "",
        title: ""
      },
      extendedSchema,
      includeColDetails
    });
    if (!isColValid) {
      isValid = false;
      brokenColumns.push(col.id);
      if (!errorsByColumn[col.id]) errorsByColumn[col.id] = [];
      errorsByColumn[col.id].push({
        message,
        errorType: errorType as COLUMN_CONFIG_ERROR_TYPES
      });
    }
  }

  const pageColIds = columns.map((col) => col.id);
  const updatedPageConfig = cloneDeep(pageData?.page_config || {});
  // Check if columns in page_config are linked to actual columns
  if (pageData?.page_config?.globalSort?.length) {
    pageData?.page_config?.globalSort?.forEach((sortItem) => {
      if (!pageColIds.includes(sortItem.id)) {
        if (fixConfigErrors) {
          updatedPageConfig.globalSort = updatedPageConfig.globalSort?.filter((sort) => sort.id !== sortItem.id);
        }
        pageConfigErrors.push(
          `Global sort column ${sortItem.id} : ${sortItem.name} not found in the current page columns and should be removed`
        );
      }
    });
  }

  if (pageData?.page_config?.columnColors?.length) {
    pageData?.page_config?.columnColors?.forEach((colorItem) => {
      if (!pageColIds.includes(colorItem.column)) {
        if (fixConfigErrors) {
          updatedPageConfig.columnColors = updatedPageConfig.columnColors?.filter(
            (color) => color.column !== colorItem.column
          );
        }
        pageConfigErrors.push(
          `Column color column ${colorItem.column} not found in the current page columns and should be removed`
        );
      }
    });
  }

  brokenColumns = [...new Set(brokenColumns)];
  return { isValid, brokenColumns, errorsByColumn, pageConfigErrors, updatedPageConfig };
};

export const getAllLookupColumnsFromColumn = (column: TableColumnType) => {
  if (!column.isLookup) return [];

  return Object.keys(column.lookupPath || {}).flatMap((key) => column.lookupPath?.[key]?.lookupColumns || []);
};

export enum COLUMN_CONDITIONAL_VISIBILITY {
  SHOW = "Show",
  HIDE = "Hide"
}

export const COLUMN_CONDITIONAL_VISIBILITY_OPTIONS = Object.keys(COLUMN_CONDITIONAL_VISIBILITY).map((key) => ({
  value: COLUMN_CONDITIONAL_VISIBILITY[key as keyof typeof COLUMN_CONDITIONAL_VISIBILITY],
  title: COLUMN_CONDITIONAL_VISIBILITY[key as keyof typeof COLUMN_CONDITIONAL_VISIBILITY]
}));

export const isBasicColumn = (column: TableColumnType) => !column.isLookup && !column.isRollup && !column.isFormula;
export const isBasicOrOnlyLookupColumn = (column: TableColumnType) => !column.isRollup && !column.isFormula;

export const columnToOptions = (columns?: TableColumnType[]) => {
  if (!columns) return [];

  return columns?.map((col) => ({
    title: `${col.nickname || col.header} (${col.type})`,
    value: col.id
  }));
};

export const selectedColumn = (selected?: TableColumnType) => {
  return selected
    ? {
        title: selected.header,
        value: selected.id
      }
    : null;
};

export const updateColumnSection = ({
  sections,
  sectionId,
  columnId
}: {
  sections?: TableViewSection[];
  sectionId: string;
  columnId: string;
}) => {
  return sections?.map((section) => {
    if (section.id === sectionId) {
      return {
        ...section,
        columns: uniq([...(section.columns || []), columnId])
      };
    }

    if (section.columns?.includes(columnId)) {
      return {
        ...section,
        columns: section.columns.filter((column: string) => column !== columnId)
      };
    }
    return section;
  });
};

export const enum ColumnTemplate {
  CREATED_BY = "createdBy",
  CREATED_AT = "createdAt",
  UPDATED_BY = "updatedBy",
  UPDATED_AT = "updatedAt",
  DEAL = "deals",
  COMPANY = "companies"
}

export const columnTemplatesOptions = [
  {
    title: "Created By",
    value: ColumnTemplate.CREATED_BY
  },
  {
    title: "Created At",
    value: ColumnTemplate.CREATED_AT
  },
  {
    title: "Updated By",
    value: ColumnTemplate.UPDATED_BY
  },
  {
    title: "Updated At",
    value: ColumnTemplate.UPDATED_AT
  },
  {
    title: "Deal",
    value: ColumnTemplate.DEAL
  },
  {
    title: "Company",
    value: ColumnTemplate.COMPANY
  }
];

const getForeignKeyAndJoinTablesToTable = (tableName: string, tableProps: TableSchema) => {
  const fKeysToTable: RecordItem[] = [];
  const joinTablesToTable: RecordItem[] = [];
  tableProps?.fk?.forEach((fk) => {
    if (fk.table === tableName) {
      fKeysToTable.push(fk);
    }
  });
  if (tableProps.joinTables?.length) {
    tableProps.joinTables.forEach((joinTable) => {
      if (
        joinTable.tableType === "join" &&
        joinTable.joinAttributes?.find((joinAttr) => joinAttr.table === tableName)
      ) {
        const attributeId = joinTable.joinAttributes?.find((joinAttr) => joinAttr.table === tableName)?.attributeId;
        joinTablesToTable.push({
          attributeId,
          table: joinTable.tableName,
          joinAttributes: joinTable.joinAttributes,
          tableType: joinTable.tableType
        });
      }
    });
  }

  return { fKeysToTable, joinTablesToTable };
};

export const generateColumnFromTemplate = ({
  template,
  tableName,
  recordTypesData,
  extendedSchema
}: {
  template: TableColumnTemplate;
  recordTypesData?: ApiRecordType[];
  extendedSchema?: ExtendedSchema;
  tableName?: string;
}) => {
  const tableProps = extendedSchema?.[tableName || ""];

  if (!tableProps?.attributeIds?.length) {
    return {
      status: "failed",
      message: "Table attributes not found"
    };
  }
  const matchingRecordType = recordTypesData?.find((recordType) => recordType.type === template.cell_type);

  let columnName = "";
  if (template.config?.name && !template.config?.isLookup && !matchingRecordType?.page_id) {
    // Basic column
    columnName = template.config?.name;
    // Check if the column is present in the current table
    if (!tableProps?.attributeIds?.includes(columnName)) {
      return {
        status: "failed",
        message: "Column not present in the current table"
      };
    }

    return {
      status: "success",
      column: {
        id: v4(),
        sortOrder: emptySortOrder,
        hasFilter: false,
        hasBulkEdit: false,
        isFormula: false,
        formula: "",
        name: template.config?.name || "",
        header: template.config?.header || "",
        type: template.cell_type as CellType,
        lookupPath: undefined,
        isLookup: false
      },
      message: `Column linked through ${columnName} added succesfully!`
    };
  }
  if (matchingRecordType?.page_id) {
    // Check matching record type
    const matchingRecordTypeTableName = matchingRecordType?.tablename;
    if (matchingRecordTypeTableName) {
      if (matchingRecordTypeTableName === tableName && !!matchingRecordType?.lookup_column?.id) {
        // Return the matching record type lookup column as is
        const col = {
          id: v4(),
          sortOrder: emptySortOrder,
          hasFilter: false,
          hasBulkEdit: false,
          isFormula: false,
          formula: "",
          name: "",
          header: template.config?.header || "",
          type: template.cell_type as CellType,
          lookupPath: matchingRecordType?.lookup_column?.lookup_path,
          isLookup: !!matchingRecordType?.lookup_column?.id
        };
        const colLabel = col.lookupPath ? getColumnLookupLabel(col, col.lookupPath["0"]) : "";

        return {
          status: "success",
          column: {
            ...col,
            lookupPath: {
              "0": {
                ...col.lookupPath?.["0"],
                lookupColumnLabel: colLabel
              }
            }
          },
          message: `Column linked through matched record type ${matchingRecordType?.lookup_column?.lookup_path?.["0"]?.lookupTableName} added succesfully!`
        };
      }
      // Find all foreign keys to the record type table
      const { fKeysToTable, joinTablesToTable } = getForeignKeyAndJoinTablesToTable(
        matchingRecordTypeTableName,
        tableProps
      );

      if (fKeysToTable?.length) {
        // Special case for Created By and Updated By
        if (["Created By", "Updated By"].includes(template.name)) {
          // Check foreign keys for these values
          if (
            fKeysToTable.find(
              (fk) =>
                (template.name === "Created By" && fk.attributeId === "created_by") ||
                (template.name === "Updated By" && fk.attributeId === "updated_by")
            )
          ) {
            if (template.name === "Created By") {
              columnName = "created_by";
            } else {
              columnName = "updated_by";
            }
            const col = {
              id: v4(),
              sortOrder: emptySortOrder,
              hasFilter: false,
              hasBulkEdit: false,
              isFormula: false,
              formula: "",
              name: "",
              header: template.config?.header || "",
              type: template.cell_type as CellType,
              lookupPath: {
                "0": {
                  lookupColumns: [],
                  lookupType: LookupTypes.FOREIGN,
                  lookupForeignKey: columnName,
                  lookupTableName: matchingRecordTypeTableName
                }
              },
              isLookup: true
            };
            const colLabel = getColumnLookupLabel(col, col.lookupPath["0"]);
            return {
              status: "success",
              column: {
                ...col,
                lookupPath: {
                  "0": {
                    ...col.lookupPath?.["0"],
                    lookupColumnLabel: colLabel
                  }
                }
              },
              message: `Column linked through ${columnName} added succesfully!`
            };
          }
        }
        const finalKeys =
          template.cell_type === CellType.PEOPLE
            ? fKeysToTable?.filter((fk) => !["created_by", "updated_by"].includes(fk.attributeId))
            : fKeysToTable || joinTablesToTable;
        if (!finalKeys?.length && template.cell_type === CellType.PEOPLE) {
          // Only created_by and updated_by are foreign keys
          return {
            status: "failed",
            message: `The ${tableName} table only has created_by and updated_by as foreign keys, please either choose those templates, or add another foreign key to people table.`
          };
        }
        const col = {
          id: v4(),
          sortOrder: emptySortOrder,
          hasFilter: false,
          hasBulkEdit: false,
          isFormula: false,
          formula: "",
          name: "",
          header: template.config?.header || "",
          type: template.cell_type as CellType,
          lookupPath: {
            "0": {
              lookupColumns: [],
              lookupType: LookupTypes.FOREIGN,
              lookupForeignKey: finalKeys[0].attributeId,
              lookupTableName: finalKeys[0]?.table
            }
          },
          isLookup: true
        };
        const colLabel = getColumnLookupLabel(col, col.lookupPath["0"]);
        // We pick the first foreign key or join table key and inform the user that there are multiple foreign keys
        return {
          status: "success",
          column: {
            ...col,
            lookupPath: {
              "0": {
                ...col.lookupPath?.["0"],
                lookupColumnLabel: colLabel
              }
            }
          },
          message:
            finalKeys?.length > 1
              ? `Multiple foreign keys found for this column's table, currently linked through ${finalKeys[0].attributeId}, please check and confirm the column config.`
              : `Column linked through ${finalKeys[0].attributeId} added succesfully!`
        };
      } else if (joinTablesToTable?.length) {
        const col = {
          id: v4(),
          sortOrder: emptySortOrder,
          hasFilter: false,
          hasBulkEdit: false,
          isFormula: false,
          formula: "",
          name: "",
          header: template.config?.header || "",
          type: template.cell_type as CellType,
          lookupPath: {
            "0": {
              lookupColumns: [],
              lookupType: LookupTypes.JOIN,
              lookupForeignKey: "",
              lookupTableName: joinTablesToTable[0]?.table
            }
          },
          isLookup: true
        };
        const colLabel = getColumnLookupLabel(col, col.lookupPath["0"]);
        return {
          status: "success",
          column: {
            ...col,
            lookupPath: {
              "0": {
                ...col.lookupPath?.["0"],
                lookupColumnLabel: colLabel
              }
            }
          },
          message:
            joinTablesToTable?.length > 1
              ? `Multiple join keys found for this column's table, currently linked through ${joinTablesToTable[0].table} please check and confirm the column config.`
              : `Column linked through join table ${joinTablesToTable[0].table} added succesfully!`
        };
      }
    }
    return {
      status: "failed",
      message: `The base table (${tableName}) has no relation to the template column's table (${matchingRecordType.tablename}), please update the table and try again.`
    };
  }
  if (template.config?.isLookup) {
    // Check template config lookup
    const firstLevelLookup = template.config?.lookupPath?.["0"];
    // If the current table has the same foreign key use template directly
    const hasSameFK = tableProps?.fk?.find((fk) => fk.attributeId === firstLevelLookup?.lookupForeignKey);
    if (firstLevelLookup?.lookupType === LookupTypes.FOREIGN && firstLevelLookup?.lookupForeignKey) {
      if (hasSameFK) {
        columnName = firstLevelLookup?.lookupForeignKey;
        if (tableProps?.attributeIds?.includes(columnName)) {
          const col = {
            id: v4(),
            sortOrder: emptySortOrder,
            hasFilter: false,
            hasBulkEdit: false,
            isFormula: false,
            formula: "",
            name: "",
            header: template.config?.header || "",
            type: template.cell_type as CellType,
            lookupPath: template.config?.lookupPath,
            isLookup: true
          };
          const colLabel = col?.lookupPath ? getColumnLookupLabel(col, col.lookupPath["0"]) : "";

          return {
            status: "success",
            column: {
              ...col,
              lookupPath: {
                ...col.lookupPath,
                "0": {
                  ...col.lookupPath?.["0"],
                  lookupColumnLabel: colLabel
                }
              }
            },
            message: `Column linked through template config lookup added succesfully!`
          };
        }
      } else {
        // Check if there is a join table to this table
        const { joinTablesToTable } = getForeignKeyAndJoinTablesToTable(
          firstLevelLookup.lookupTableName || "",
          tableProps
        );

        if (joinTablesToTable?.length) {
          const updatedLookupPath: RecordItem = {};
          updatedLookupPath["0"] = {
            lookupColumns: [],
            lookupType: LookupTypes.JOIN,
            lookupForeignKey: "",
            lookupTableName: joinTablesToTable[0]?.table
          };
          Object.keys(template.config?.lookupPath || {}).forEach((level) => {
            const finalLevel = parseInt(level, 10) + 1;

            if (level === "0") {
              // Check if foreign key attribute is on the join table
              const lookupDetails = template.config?.lookupPath?.[level];
              const joinTableProps = extendedSchema?.[joinTablesToTable[0]?.table || ""];
              if (joinTableProps) {
                const isForeignKey = joinTableProps?.fk?.find(
                  (fk) => fk.attributeId === lookupDetails?.lookupForeignKey
                );
                if (isForeignKey) {
                  updatedLookupPath[finalLevel] = template.config?.lookupPath?.[level];
                } else {
                  // Replace this with the attribute id of the foreign key
                  const matchedForeignKey = joinTableProps?.fk?.find(
                    (fk) => fk.table === lookupDetails?.lookupTableName
                  );
                  if (matchedForeignKey) {
                    updatedLookupPath[finalLevel] = {
                      ...template.config?.lookupPath?.[level],
                      lookupForeignKey: matchedForeignKey?.attributeId
                    };
                  }
                }
              }
            } else {
              updatedLookupPath[finalLevel] = template.config?.lookupPath?.[level];
            }
          });
          const col = {
            id: v4(),
            sortOrder: emptySortOrder,
            hasFilter: false,
            hasBulkEdit: false,
            isFormula: false,
            formula: "",
            name: "",
            header: template.config?.header || "",
            type: template.cell_type as CellType,
            lookupPath: updatedLookupPath,
            isLookup: true
          };
          const colLabel = col?.lookupPath ? getColumnLookupLabel(col, col.lookupPath["0"]) : "";

          return {
            status: "success",
            column: {
              ...col,
              lookupPath: {
                ...col.lookupPath,
                "0": {
                  ...col.lookupPath?.["0"],
                  lookupColumnLabel: colLabel
                }
              }
            },
            message: `Column linked through join table ${updatedLookupPath["0"].lookupTableName} added succesfully!`
          };
        }
      }
      return {
        status: "failed",
        message: `The base table (${tableName}) has no relation to the template column's table (${firstLevelLookup.lookupTableName}), please update the table and try again.`
      };
    }

    if (firstLevelLookup?.lookupType === LookupTypes.JOIN) {
      // Check if first level is also join table to current table
      const hasJoinTable = tableProps?.joinTables?.find(
        (joinTable) => joinTable.tableName === firstLevelLookup?.lookupTableName
      );
      if (hasJoinTable) {
        const col = {
          id: v4(),
          sortOrder: emptySortOrder,
          hasFilter: false,
          hasBulkEdit: false,
          isFormula: false,
          formula: "",
          name: "",
          header: template.config?.header || "",
          type: template.cell_type as CellType,
          lookupPath: template.config?.lookupPath,
          isLookup: true
        };
        const colLabel = col?.lookupPath ? getColumnLookupLabel(col, col.lookupPath["0"]) : "";

        return {
          status: "success",
          column: {
            ...col,
            lookupPath: {
              ...col.lookupPath,
              "0": {
                ...col.lookupPath?.["0"],
                lookupColumnLabel: colLabel
              }
            }
          },
          message: `Column, linked through template config lookup, added succesfully!`
        };
      } else {
        // Find the level in the lookup path to which current table is related
        const lookupLevels = Object.keys(template.config?.lookupPath || {});
        let matchingLevel = 0;
        for (const level of lookupLevels) {
          const lookupDetails = template.config?.lookupPath?.[level];
          if (lookupDetails?.lookupType === LookupTypes.FOREIGN) {
            const isForeignKey = tableProps?.fk?.find((fk) => fk.attributeId === lookupDetails?.lookupForeignKey);
            if (isForeignKey) {
              matchingLevel = parseInt(level, 10);
              break;
            }
          } else {
            const joinTable = tableProps?.joinTables?.find(
              (joinTable) => joinTable.tableName === lookupDetails?.lookupTableName
            );
            if (joinTable) {
              matchingLevel = parseInt(level, 10);
              break;
            }
          }
        }
        // Will be greated than 0 if found
        if (matchingLevel) {
          const updatedLookupPath: RecordItem = {};
          let currentLevel = 0;
          for (const level of lookupLevels) {
            const lookupDetails = template.config?.lookupPath?.[level];
            if (parseInt(level, 10) >= matchingLevel) {
              updatedLookupPath[currentLevel] = lookupDetails;
              currentLevel++;
            }
          }
          const col = {
            id: v4(),
            sortOrder: emptySortOrder,
            hasFilter: false,
            hasBulkEdit: false,
            isFormula: false,
            formula: "",
            name: "",
            header: template.config?.header || "",
            type: template.cell_type as CellType,
            lookupPath: updatedLookupPath,
            isLookup: true
          };
          const colLabel = col?.lookupPath ? getColumnLookupLabel(col, col.lookupPath["0"]) : "";
          return {
            status: "success",
            column: {
              ...col,
              lookupPath: {
                "0": {
                  ...col.lookupPath?.["0"],
                  lookupColumnLabel: colLabel
                }
              }
            },
            message: `Column linked through join table ${updatedLookupPath["0"].lookupTableName} added succesfully!`
          };
        } else {
          // No matching attributes found, we need to find matching relations to the last lookupLevel
          const lastLookupLevel = lookupLevels[lookupLevels.length - 1];
          const lastLookupDetails = template.config?.lookupPath?.[lastLookupLevel];
          const lastLookupTable = lastLookupDetails?.lookupTableName;

          if (lastLookupTable && lastLookupDetails?.lookupType === LookupTypes.FOREIGN) {
            // Find a foreign key to the table
            const { fKeysToTable, joinTablesToTable } = getForeignKeyAndJoinTablesToTable(lastLookupTable, tableProps);
            if (!fKeysToTable?.length) {
              // We should check the current foreign keys if they have a relation to the last lookup table
              tableProps.fk.forEach((fk) => {
                const fkTableProps = extendedSchema?.[fk.table];
                if (
                  fkTableProps?.fk?.find(
                    (fk) => fk.table === lastLookupTable && fk.attributeId === lastLookupDetails?.lookupForeignKey
                  )
                ) {
                  fKeysToTable.unshift(fk);
                }
              });
            }
            if (fKeysToTable?.length || joinTablesToTable?.length) {
              const updatedLookupPath: RecordItem = {};
              if (fKeysToTable?.length) {
                updatedLookupPath["0"] = {
                  lookupColumns: [],
                  lookupType: LookupTypes.FOREIGN,
                  lookupForeignKey: fKeysToTable[0].attributeId,
                  lookupTableName: fKeysToTable[0]?.table
                };
              } else {
                updatedLookupPath["0"] = {
                  lookupColumns: [],
                  lookupType: LookupTypes.JOIN,
                  lookupForeignKey: "",
                  lookupTableName: joinTablesToTable[0]?.table
                };
              }
              // Add last lookup level
              updatedLookupPath["1"] = template.config?.lookupPath?.[lastLookupLevel];
              const col = {
                id: v4(),
                sortOrder: emptySortOrder,
                hasFilter: false,
                hasBulkEdit: false,
                isFormula: false,
                formula: "",
                name: "",
                header: template.config?.header || "",
                type: template.cell_type as CellType,
                lookupPath: updatedLookupPath,
                isLookup: true
              };
              const colLabel = getColumnLookupLabel(col, col.lookupPath["0"]);
              // We pick the first foreign key or join table key and inform the user that there are multiple foreign keys
              return {
                status: "success",
                column: {
                  ...col,
                  lookupPath: {
                    "0": {
                      ...col.lookupPath?.["0"],
                      lookupColumnLabel: colLabel
                    }
                  }
                },
                message:
                  fKeysToTable?.length > 1 || joinTablesToTable?.length > 1
                    ? `Multiple  ${
                        fKeysToTable?.length > 1 ? "foreign" : "join table "
                      } keys found for this column's table, please check and confirm the column config.`
                    : `Column linked through join table ${updatedLookupPath["0"].lookupTableName} added succesfully!`
              };
            }
          }
        }
      }
    }
  }

  const newColumn: TableColumnType = {
    id: v4(),
    sortOrder: emptySortOrder,
    hasFilter: false,
    hasBulkEdit: false,
    isFormula: false,
    formula: "",
    name: template.config?.name || "",
    header: template.config?.header || "",
    type: template.cell_type as CellType,
    lookupPath: template.config?.lookupPath,
    isLookup: !!template.config?.isLookup
  };

  return { status: "success", column: newColumn, message: "Column added from template config!" };
};

export const generateFilterFromTemplate = ({
  template,
  extendedSchema,
  tableName
}: {
  template: TableFilterTemplate;
  extendedSchema?: ExtendedSchema;
  tableName?: string;
}) => {
  const tableProps = extendedSchema?.[tableName || ""];
  if (!tableProps?.attributeIds?.length) {
    return {
      status: "failed",
      message: "Table attributes not found"
    };
  }

  if (template.config?.filterField) {
    // Check if the column is present in the current table
    if (!tableProps?.attributeIds?.includes(template.config?.filterField)) {
      return {
        status: "failed",
        message: "Filter field column not present in the current table"
      };
    }

    return {
      status: "success",
      filter: {
        id: v4(),
        filterLookupPath: undefined,
        filterField: template.config?.filterField || "",
        filterValue: template.config?.filterValue || "",
        filterOperator: template?.config?.filterOperator || "",
        isFilterTextSearch: template?.config?.isFilterTextSearch
      },
      message: `Filter linked through ${template.config?.filterField} added succesfully!`
    };
  }

  const newFilter: TableFilterType = {
    id: v4(),
    filterLookupPath: undefined,
    filterField: "",
    filterValue: "",
    filterOperator: FILTER_OPERATOR.EQUALS
  };

  return { status: "success", filter: newFilter, message: "filter added from template config!" };
};

export const FORM_WEBHOOK_ACTION_OPTIONS = [
  {
    value: "Add",
    title: "Add"
  },
  {
    value: "Update",
    title: "Update"
  }
];

export const pageTableActionsColumns = [
  {
    id: "id",
    name: "id",
    hasFilter: false,
    header: "ID",
    type: CellType.TEXT,
    hasBulkEdit: false,
    views: ["grid"],
    sortOrder: emptySortOrder
  },
  {
    id: "name",
    name: "name",
    hasFilter: false,
    header: "Name",
    type: CellType.TEXT,
    hasBulkEdit: false,
    views: ["grid"],
    sortOrder: emptySortOrder
  },
  {
    id: "type",
    name: "type",
    hasFilter: false,
    header: "Type",
    type: CellType.TEXT,
    hasBulkEdit: false,
    views: ["grid"],
    sortOrder: emptySortOrder
  },
  {
    id: "addDividerBtn",
    name: "addDividerBtn",
    hasFilter: false,
    header: "Add Divider",
    type: CellType.BUTTON,
    cellConfig: {
      label: "Add Divider"
    },
    hasBulkEdit: false,
    views: ["grid"],
    sortOrder: emptySortOrder,
    disableSorting: true
  },
  {
    id: "editBtn",
    name: "editBtn",
    hasFilter: false,
    header: "Edit",
    type: CellType.BUTTON,
    cellConfig: {
      label: "Edit"
    },
    hasBulkEdit: false,
    views: ["grid"],
    sortOrder: emptySortOrder,
    disableSorting: true
  },
  {
    id: "editBaseActionBtn",
    name: "editBaseActionBtn",
    hasFilter: false,
    header: "Edit Base UI Action",
    type: CellType.BUTTON,
    cellConfig: {
      label: "Edit Action"
    },
    hasBulkEdit: false,
    views: ["grid"],
    sortOrder: emptySortOrder,
    disableSorting: true
  },
  {
    id: "deleteBtn",
    name: "deleteBtn",
    hasFilter: false,
    header: "Delete",
    type: CellType.BUTTON,
    cellConfig: {
      label: "Delete"
    },
    hasBulkEdit: false,
    views: ["grid"],
    sortOrder: emptySortOrder,
    disableSorting: true
  }
];

export const ACTION_TYPES_OPTIONS = Object.keys(ActionType)
  .sort()
  .map((key) => ({
    title: ActionType?.[key as keyof typeof ActionType],
    value: ActionType?.[key as keyof typeof ActionType]
  }));

export const getPropNameForFilterType = (filterType: APP_FILTER_TYPES) => {
  let propToCheck: keyof TableColumnType =
    filterType === APP_FILTER_TYPES.COLUMN_OPTIONS ? "columnFilters" : "lookupFilters";
  if (filterType === APP_FILTER_TYPES.ADD_FILTER) {
    propToCheck = "addFilters";
  }
  return propToCheck;
};

export const getDefaultHeaderValueForColumn = (column: Partial<TableColumnType>) => {
  // Check if the new header value is null or undefined  and the name value is present
  if (column.header) return column.header;
  if (column.name) {
    // Split the column name by '_' and join with ' ' space
    const modifiedParts = column.name.split("_").map((part) => {
      if (part === part.toLowerCase() && part.indexOf("_") > -1) {
        // If the part is a single, lowercase word with '_'
        return toUpper(part);
      } else {
        // Otherwise, capitalize the first letter
        return startCase(part);
      }
    });

    // Join the modified parts to create the new header value
    const newHeaderValue = modifiedParts.join(" ");

    return newHeaderValue;
  }
};
export const getCellTypeFromColumnDBFormat = (format?: string) => {
  if (!format) return CellType.TEXT;
  switch (format) {
    case "boolean":
      return CellType.BOOLEAN;
    case "datetime":
      return CellType.DATETIME;
    case "date":
      return CellType.DATE;
    case "jsonb":
    case "json":
      return CellType.JSON;
    default:
      return CellType.TEXT;
  }
};

export const COLUMN_SECTIONS_TABLE_COLUMNS: GridViewColumn[] = [
  {
    id: "title",
    name: "title",
    hasFilter: false,
    header: "Name",
    type: CellType.TEXT,
    hasBulkEdit: false,
    sortOrder: emptySortOrder
  },
  {
    id: "columns",
    name: "columns",
    hasFilter: false,
    header: "Columns",
    type: CellType.TEXT,
    hasBulkEdit: false,
    sortOrder: emptySortOrder,
    isMultiple: true
  },
  {
    id: "editBtn",
    name: "editBtn",
    hasFilter: false,
    header: "Edit",
    type: CellType.BUTTON,
    cellConfig: {
      label: "Edit"
    },
    hasBulkEdit: false,
    sortOrder: emptySortOrder,
    disableSorting: true
  },
  {
    id: "deleteBtn",
    name: "deleteBtn",
    hasFilter: false,
    header: "Delete",
    type: CellType.BUTTON,
    cellConfig: {
      label: "Delete"
    },
    hasBulkEdit: false,
    sortOrder: emptySortOrder,
    disableSorting: true
  }
];

export const GRID_COLUMN_GROUPS_TABLE_COLUMNS: GridViewColumn[] = [
  {
    id: "title",
    name: "title",
    hasFilter: false,
    header: "Name",
    type: CellType.TEXT,
    hasBulkEdit: false,
    sortOrder: emptySortOrder
  },
  {
    id: "columns",
    name: "columns",
    hasFilter: false,
    header: "Columns",
    type: CellType.TEXT,
    hasBulkEdit: false,
    sortOrder: emptySortOrder,
    isMultiple: true
  },
  {
    id: "editBtn",
    name: "editBtn",
    hasFilter: false,
    header: "Edit",
    type: CellType.BUTTON,
    cellConfig: {
      label: "Edit"
    },
    hasBulkEdit: false,
    sortOrder: emptySortOrder,
    disableSorting: true
  },
  {
    id: "deleteBtn",
    name: "deleteBtn",
    hasFilter: false,
    header: "Delete",
    type: CellType.BUTTON,
    cellConfig: {
      label: "Delete"
    },
    hasBulkEdit: false,
    sortOrder: emptySortOrder,
    disableSorting: true
  }
];

export const getLinkedColumnsFromTableProps = (
  tableProps: TableSchema,
  tableName: string,
  extendedSchema?: ExtendedSchema
): SelectOptions => {
  const fKeys = tableProps?.fk?.filter((fkey) => fkey.table !== tableName);
  // Also add all join table keys
  const joinTableKeys = tableProps?.joinTables?.map((joinTable) => joinTable);
  const finalLinkedOptions = [];
  if (fKeys?.length) {
    finalLinkedOptions.push(
      ...fKeys.map((fkey) => ({
        title: fkey.attributeId + "(" + fkey.table + ")",
        value: fkey.attributeId,
        record: {
          columnName: fkey.attributeId,
          tableName: fkey.table,
          type: RELATION_TYPES.FOREIGN
        }
      }))
    );
  }
  if (joinTableKeys?.length) {
    const joinOpts: SelectOptions = [];
    joinTableKeys.forEach((joinTable) => {
      const joinTableProps = extendedSchema?.[joinTable.tableName];
      joinTable.joinAttributes?.forEach((joinAttr) => {
        if (joinAttr.table !== tableName && joinAttr.attributeId !== "id") {
          joinOpts.push({
            title: joinAttr.attributeId + "(" + joinTable.tableName + ")",
            value: `${joinAttr.attributeId}_${joinTable.tableName}`,
            record: {
              columnName: joinAttr.attributeId,
              syncLabel: `${joinAttr.attributeId}_${joinTable.tableName}`,
              tableName: joinTable.tableName,
              type: RELATION_TYPES.JOIN,
              useColumnHint:
                joinTable?.fkColumns?.filter((fkCol) => fkCol.table === tableName)?.length > 1
                  ? joinTable.joinAttributes?.find((joinAttr) => joinAttr.table === tableName)?.attributeId
                  : "",
              hasIsDeleted: !!joinTableProps?.attributeIds?.includes("is_deleted")
            }
          });
        }
      });
    });
    finalLinkedOptions.push(...joinOpts);
  }
  return finalLinkedOptions;
};

export const isGenericCellOnlyLookup = (column: TableColumnType, allColumns: TableColumnType[]) => {
  const isColGenericWithEdit =
    column?.cellConfig &&
    column.cellConfig?.genericConfig?.layout === GENERIC_CELL_LAYOUTS.COMBINATION_CELL &&
    column.isEditable;

  if (!isColGenericWithEdit) return false;
  let isGenericCellAllLookup = true;
  column.cellConfig?.genericConfig?.columns?.forEach((column) => {
    const editCol = allColumns.find((col) => col.id === column.columnId);
    if (editCol?.type === CellType.TEXT && !editCol?.isLookup && editCol?.name) {
      isGenericCellAllLookup = false;
    }
  });
  return isGenericCellAllLookup;
};

export const FILTERS_CONFIG_TABLE_COLUMNS: GridViewColumn[] = [
  {
    id: "header",
    name: "header",
    hasFilter: false,
    header: "Column Name",
    type: CellType.TEXT
  },
  {
    id: "id",
    name: "id",
    hasFilter: false,
    header: "Column ID",
    type: CellType.TEXT
  },
  {
    id: "filterSortOrder",
    name: "filterSortOrder",
    hasFilter: false,
    header: "Filter Sort Order",
    type: CellType.TEXT
  },
  {
    id: "hasFilter",
    name: "hasFilter",
    hasFilter: false,
    header: "Has Filter",
    type: CellType.BOOLEAN,
    cellConfig: {
      booleanProps: {
        showFalseState: true
      }
    },
    isEditable: true
  },
  {
    id: "showFilterExposed",
    name: "showFilterExposed",
    hasFilter: false,
    header: "Show Filter Exposed",
    type: CellType.BOOLEAN,
    cellConfig: {
      booleanProps: {
        showFalseState: true
      }
    },
    isEditable: true
  }
];

export const BULK_EDIT_CONFIG_TABLE_COLUMNS: GridViewColumn[] = [
  {
    id: "header",
    name: "header",
    hasFilter: false,
    header: "Column Name",
    type: CellType.TEXT
  },
  {
    id: "id",
    name: "id",
    hasFilter: false,
    header: "Column ID",
    type: CellType.TEXT
  },
  {
    id: "bulkEditSortOrder",
    name: "bulkEditSortOrder",
    hasFilter: false,
    header: "Sort Order",
    type: CellType.TEXT
  },
  {
    id: "hasBulkEdit",
    name: "hasBulkEdit",
    hasFilter: false,
    header: "Has Bulk Edit",
    type: CellType.BOOLEAN,
    cellConfig: {
      booleanProps: {
        showFalseState: true
      }
    },
    isEditable: true
  }
];

export const getNotesTableLinkCol = (extendedSchema?: ExtendedSchema, tableName?: string, parentTableName?: string) => {
  if (!extendedSchema?.["notes_links"] || !tableName || tableName !== "notes" || !parentTableName) return undefined;
  // Find foreign key to parentTableName in notes_links
  const fkToParent = getNotesLinkForeignKey(parentTableName, extendedSchema);
  if (!fkToParent) return undefined;
  return fkToParent.attributeId;
};
