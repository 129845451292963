import { ClassValue, clsx } from "clsx";
import isEmpty from "lodash/isEmpty";
import isObject from "lodash/isObject";
import isPlainObject from "lodash/isPlainObject";
import isUndefined from "lodash/isUndefined";
import omitBy from "lodash/omitBy";
import pick from "lodash/pick";
import mathEvaluator from "math-expression-evaluator";
import { twMerge } from "tailwind-merge";
import omit from "lodash/omit";
import { CompositePKey, ExtendedSchema, FKey, TableColumnProperties, TableSchema } from "utils/schema";

import { isBasicOrOnlyLookupColumn } from "components/Admin/utils";
import { CellGroupValueInput, CellGroupValueType } from "components/CellGroup/utils";
import { testRule } from "utils/visibilityRuleUtils";
import { AggregateFunctionConfigType, ApiRecordType, User } from "types/apiTypes";
import type {
  ColumnCellAdditionalValueFields,
  ColumnColorType,
  ColumnRule,
  DefaultValue,
  FormTableRecordInputType,
  LookupEntry,
  MultiTitleItem,
  Page,
  SortItem,
  TableColumnType,
  TableFilterType,
  TableViewConfig,
  TableViewType
} from "types/baTypes";
import { AddressComplete, GridViewColumn, InfoLoggerProps, RecordItem } from "types/common";
import {
  getImageColumnLabel,
  isColumnForTextFieldOnJoinTable,
  isColumnTextWithOptions,
  isLookupForeignKeyOnJoinTable
} from "utils/columnUtils";
import {
  CONTACTS_PHONE_NUMBERS_TABLE_NAME,
  CellType,
  CellTypeDefaultPageTablename,
  CellTypesAvailableForUrl,
  CellTypesWithUrl,
  DB_VIEWS,
  EMPTY_FIELD,
  FILTER_OPERATOR,
  GENERIC_CELL_LAYOUTS,
  LookupTypes,
  PLACES_ADDRESS_FIELDS,
  SPECIAL_DEFAULTS,
  SPECIAL_DEFAULTS_VALUES,
  SelectCellTypes,
  TEXT_TYPE_CELLS,
  ViewOption,
  emptySortOrder
} from "utils/constants";
import {
  findFeaturedOrFirstFromJoinFiles,
  getBeforeAfterCellValuesFromRecord,
  getFileIdFromRecordBasedOnLoookupPath,
  getGenericCellValuesFromRecord,
  getImageColumnForRecordType,
  isColumnFileTag,
  isColumnNestedFileTag,
  parseFormulaToValidExpression
} from "utils/dataUtils";
import {
  constructLookupFilter,
  constructMultiFilter,
  constructORFilterFromGroup,
  getSupabaseFilterValue,
  isFilterNonTextType
} from "utils/filterUtils";
import { formatAddress, formatDate, formatDateTime } from "utils/format";

export const setUpNestedLookup = ({
  lookupPath,
  tableNamesForInner,
  compositeKeysByTable,
  columnType,
  aggregateFunctionConfig,
  isCombinedAggregate
}: {
  lookupPath: { [lookupLevel: string]: LookupEntry } | undefined;
  tableNamesForInner?: Array<string>; // Is mutated
  compositeKeysByTable?: { [key: string]: CompositePKey[] };
  columnType?: CellType;
  aggregateFunctionConfig?: AggregateFunctionConfigType;
  isCombinedAggregate?: boolean;
}) => {
  let select = "";
  if (lookupPath) {
    let openParenthesesCount: string[] = [];
    const lookupLevels = Object.keys(lookupPath);
    // Check if lookupPath has lookupColumns at the last level
    if (!lookupLevels.length || !lookupPath[lookupLevels[lookupLevels.length - 1]].lookupColumns?.length) {
      return select;
    }
    Object.keys(lookupPath).forEach((lookupLevel, index) => {
      let compositeKeys: CompositePKey[] = [];
      const isLastLevel = index === Object.keys(lookupPath).length - 1;
      const {
        lookupTableName,
        lookupColumns,
        lookupForeignKey,
        lookupColumnLabel,
        foreignLookupColumns,
        lookupTableHint,
        lookupType
      } = lookupPath[lookupLevel];
      const isExternalForiegnLookup = lookupType === LookupTypes.FOREIGN_EXTERNAL;
      let finalTableName = isExternalForiegnLookup ? lookupTableName : lookupForeignKey || lookupTableName;
      const finalLookupTableName = finalTableName; // tableNamesForInner only includes lookupTableName or lookupForeignKey
      if (lookupTableHint) {
        finalTableName += "!" + lookupTableHint;
      }
      if (
        finalTableName &&
        tableNamesForInner?.includes(finalLookupTableName) &&
        !finalTableName.endsWith("!inner") &&
        !(parseInt(lookupLevel, 10) > 0 && lookupTableHint)
      ) {
        finalTableName = `${finalTableName}!inner`;
      }
      if (
        !lookupForeignKey &&
        lookupTableName &&
        compositeKeysByTable?.[lookupTableName]?.length &&
        !isExternalForiegnLookup
      ) {
        // Add composite pk columns to select if lookup path does not include it alreayd
        compositeKeys = [...(compositeKeysByTable[lookupTableName] || [])];
      }
      if (lookupColumnLabel) {
        finalTableName = `${lookupColumnLabel}:${finalTableName}`;
      }
      if (lookupColumns?.length) {
        // If it's an aggregate function column select is different
        if (
          aggregateFunctionConfig?.function &&
          aggregateFunctionConfig?.lookupColumnName &&
          aggregateFunctionConfig?.lookupLevel === lookupLevel
        ) {
          // Aggregate functions are not record types, they can only have levels 0 and 1
          if (lookupLevel === "0") {
            select += `${finalTableName}(`;
          } else {
            select += `${lookupPath["0"]?.lookupColumnLabel ? lookupPath["0"]?.lookupColumnLabel + ":" + lookupPath["0"]?.lookupTableName : lookupPath["0"]?.lookupTableName}(${isCombinedAggregate ? "" : "id,"}${finalTableName}(`;
          }
          // Adding extra ) at the end as we trim the last character of select at the end
          select += `${aggregateFunctionConfig?.lookupColumnName}.${aggregateFunctionConfig?.function}()))`;
          if (lookupLevel === "1") {
            select += ")";
          }
        } else {
          const isFileType =
            compositeKeys?.find((item: CompositePKey) => item.table === "files")?.attributeId === finalTableName ||
            finalTableName === "files" ||
            lookupTableName === "files";
          const isNoteType =
            compositeKeys?.find((item: CompositePKey) => item.table === "notes")?.attributeId === finalTableName ||
            finalTableName === "notes" ||
            (lookupTableName === "notes" && !lookupForeignKey);
          const isFileTagTable = lookupTableName === "files_tags";
          const isTilesTable = lookupTableName === "tiles";
          const isContactsPhoneNumbersTable = lookupTableName === CONTACTS_PHONE_NUMBERS_TABLE_NAME;
          // In case of file type with need to filter metadata to only get the mimetype
          const lookupColumnsWithoutMetadata = isFileType
            ? lookupColumns.filter((col) => col !== "metadata")
            : lookupColumns;
          const columnToAddWhenIsFile = columnType === CellType.FILE ? ["metadata->mimetype"] : [];
          // ##HARDCODED: Special cases for files and notes
          const finalLookupColumns = Array.from(new Set(["id", ...lookupColumnsWithoutMetadata])).concat(
            isFileType ? ["file_type", "name", "thumbnail_path", ...columnToAddWhenIsFile] : [],
            isNoteType ? ["source_root_title", "source_root_url", "source_url", "source_title"] : [],
            isFileTagTable ? ["tag_id, file_id"] : [],
            isTilesTable ? ["public_id"] : [],
            isContactsPhoneNumbersTable
              ? [
                  "id",
                  "contact_id",
                  "phone",
                  "is_primary_number",
                  "phone_type",
                  "is_not_in_service",
                  "is_wrong_number",
                  "is_dnc"
                ]
              : []
          );

          if (foreignLookupColumns?.length) {
            select += `${finalTableName} (`;

            finalLookupColumns.forEach((lookupColumn) => {
              const foreignLookupColumn = foreignLookupColumns.find(
                (foreignLookupColumn) => foreignLookupColumn.lookupForeignKey === lookupColumn
              );

              if (foreignLookupColumn && foreignLookupColumn?.lookupColumns?.length) {
                select += `${lookupColumn}(${foreignLookupColumn?.lookupColumns?.join(", ")}),`;
              } else {
                select += `${lookupColumn}, `;
              }
            });
          } else {
            select += `${finalTableName} (${
              finalLookupColumns?.length > 1 ? finalLookupColumns.join(", ") : finalLookupColumns[0] + ", "
            }`;
          }
          if (compositeKeys?.length) {
            compositeKeys.forEach((cKey: CompositePKey) => {
              if (!select.includes(cKey.attributeId)) {
                select += `${!select.trim().endsWith(",") ? "," : ""}${cKey.attributeId},`;
              }
            });
            compositeKeys = [];
          }
          if (isLastLevel) {
            if (select.trim().endsWith(",")) {
              select = select.trim().slice(0, -1);
            }
            select += openParenthesesCount.join("");
            openParenthesesCount = [];
            select += "), ";
          } else {
            select += !select.trim().endsWith(",") ? `, ` : "";
            openParenthesesCount.push(")");
          }
        }
      } else {
        // Don't add duplicate label
        if ((lookupColumnLabel && !select.includes(lookupColumnLabel)) || !lookupColumnLabel) {
          select += `${finalTableName} (`;
          openParenthesesCount.push(")");
          if (compositeKeys?.length) {
            compositeKeys.forEach((cKey: CompositePKey) => {
              select += `${cKey.attributeId},`;
            });
            compositeKeys = [];
          } else {
            select += isCombinedAggregate ? "" : "id, ";
          }
        }
      }
    });
  }

  return select;
};

export const constructSupabaseSelect = ({
  columns,
  baseTableName,
  filters,
  compositeKeysByTable,
  tableFilters,
  baseTableCompositeKeys,
  sortLookupColumns,
  isCombinedAggregateFetch,
  nonIDPrimaryKey
}: {
  columns: Array<TableColumnType>;
  baseTableName: string;
  filters?: TableFilterType[];
  compositeKeysByTable?: { [key: string]: CompositePKey[] };
  tableFilters?: TableFilterType[];
  baseTableCompositeKeys?: CompositePKey[];
  sortLookupColumns?: SortItem[];
  isCombinedAggregateFetch?: boolean;
  nonIDPrimaryKey?: string;
}) => {
  let select = "";
  if (!columns.length) {
    return "*";
  }

  const tableNamesWithInnerFromFilters: Record<string, string[]> = {};
  const finalFilters = [...(filters || []), ...(tableFilters || [])];

  // if compositeKey is present add it's attribute to the select
  if (baseTableCompositeKeys?.length) {
    baseTableCompositeKeys.forEach((cKey: CompositePKey) => {
      if (!select.includes(cKey.attributeId)) {
        select += `${cKey.attributeId},`;
      }
    });
  }

  if (finalFilters?.length) {
    finalFilters.forEach((filter) => {
      if (filter.column?.id && filter?.column?.isLookup && !filter?.excludeFromInner && !filter.isViewFilter) {
        const { lookupPath } = filter.column;
        const tableName = lookupPath?.["0"]?.lookupForeignKey || lookupPath?.["0"]?.lookupTableName;
        if (tableName) {
          if (!tableNamesWithInnerFromFilters[filter.column.id]) {
            tableNamesWithInnerFromFilters[filter.column.id] = [];
          }
          tableNamesWithInnerFromFilters[filter.column.id].push(tableName);
        }
        // For project filter inner needs to be added to all levels
        if (filter.column?.type === CellType.DEAL || filter.innerAtAllLevels) {
          Object.keys(lookupPath || {}).forEach((lookupLevel) => {
            const tableName = lookupPath?.[lookupLevel]?.lookupForeignKey || lookupPath?.[lookupLevel]?.lookupTableName;
            if (
              tableName &&
              !tableNamesWithInnerFromFilters[filter.column?.id || ""].includes(tableName) &&
              lookupLevel !== "0"
            ) {
              tableNamesWithInnerFromFilters[filter.column?.id || ""].push(tableName);
            }
          });
        }
      }
      if ((filter.filterLookupPath || filter.column?.lookupPath) && !filter.isViewFilter) {
        const finalLookupPath = filter.filterLookupPath || filter.column?.lookupPath; // lookupPath used for view filters
        const finalID = filter.column?.id || filter.id || "";
        if (finalLookupPath && !filter?.excludeFromInner) {
          const finalTableNames: string[] = [];
          Object.keys(finalLookupPath).forEach((lookupLevel) => {
            const {
              lookupTableName = "",
              lookupForeignKey = "",
              lookupColumnLabel = "",
              lookupType
            } = filter?.filterLookupPath?.[lookupLevel] || {};
            const tableName =
              lookupType === LookupTypes.FOREIGN_EXTERNAL // This condition was added to suppot list_properties tab filter check before removing it
                ? lookupTableName
                : lookupColumnLabel || lookupForeignKey || lookupTableName;
            if (tableName) {
              finalTableNames.push(tableName);
            }
          });
          if (finalTableNames?.length) {
            if (!tableNamesWithInnerFromFilters[finalID]) {
              tableNamesWithInnerFromFilters[finalID] = [];
            }
            tableNamesWithInnerFromFilters[finalID] = Array.from(
              new Set([...tableNamesWithInnerFromFilters[finalID], ...finalTableNames])
            );
          }
        }
      }
    });
  }
  // Add check to see if columns include filter columns (if not add them to columns)
  const finalColumns = [...columns];

  if (tableFilters?.length) {
    // Add lookup filter columns to columns
    tableFilters.forEach((filter) => {
      let finalFilterLookupPath = filter.filterLookupPath;
      if (filter.filterOperator === FILTER_OPERATOR.OR && filter.filterGroup?.length) {
        // Pick the first filter with lookupPath as the all filters in the OR group need to be for the same table
        const lookupPathFilter = filter.filterGroup.find((filter) => filter.filterLookupPath);
        if (lookupPathFilter?.filterLookupPath) {
          finalFilterLookupPath = lookupPathFilter.filterLookupPath;
        }
      }

      if (finalFilterLookupPath) {
        // Add inner at each level to filter parent records
        const finalTableNames: string[] = [];
        Object.keys(finalFilterLookupPath).forEach((lookupLevel) => {
          const { lookupTableName = "", lookupForeignKey = "" } = finalFilterLookupPath?.[lookupLevel] || {};

          const tableName = lookupForeignKey || lookupTableName;
          if (tableName) {
            finalTableNames.push(tableName);
          }
        });
        if (finalTableNames?.length) {
          tableNamesWithInnerFromFilters[filter.id || ""] = Array.from(
            new Set([...(tableNamesWithInnerFromFilters[filter.id || ""] || []), ...finalTableNames])
          );
        }

        finalColumns.unshift({
          id: filter.id || "",
          name: filter.filterOperator || "",
          header: "Filter Column",
          type: CellType.TEXT,
          isLookup: true,
          lookupPath: finalFilterLookupPath,
          hasFilter: false,
          hasBulkEdit: false,
          sortOrder: { ...emptySortOrder }
        });
      }
    });
  }
  // Check for filter column fetch
  if (finalFilters?.length && columns?.length <= 1) {
    finalFilters.forEach((filter) => {
      // Only for lookup filters
      if (filter.filterLookupPath && filter.column?.id && !filter.isViewFilter) {
        const level0TableOrForeignKey =
          filter.filterLookupPath?.["0"]?.lookupForeignKey || filter.filterLookupPath?.["0"]?.lookupTableName;
        const matchingColumn = columns.find((column) => {
          if (column.isLookup) {
            const colLevel0TableOrForeignKey =
              column.lookupPath?.["0"]?.lookupForeignKey || column.lookupPath?.["0"]?.lookupTableName;
            return colLevel0TableOrForeignKey === level0TableOrForeignKey;
          }
          return column?.name && filter.column?.name ? column.name === filter.filterOperator : false;
        });
        if (!matchingColumn) {
          finalColumns.push({
            id: filter.id || "",
            name: filter.filterOperator || "",
            header: "Filter Column",
            type: CellType.TEXT,
            isLookup: true,
            lookupPath: filter.filterLookupPath,
            hasFilter: false,
            hasBulkEdit: false,
            sortOrder: { ...emptySortOrder }
          });
        }
      }
    });
  }
  // Check if each column in selected filters is in final columns as well
  if (filters?.length) {
    filters.forEach((filter) => {
      if (filter.column?.id && !filter.isViewFilter && filter.filterOperator !== FILTER_OPERATOR.EMPTY) {
        const matchingCol = finalColumns.find((col) => col.id === filter.column?.id);
        if (!matchingCol) {
          finalColumns.push(filter.column);
        }
      }
    });
  }
  // Check if sorting columns need to be added TODO: Uncomment once sorting by related tables is supported
  if (sortLookupColumns?.length) {
    sortLookupColumns.forEach((sortColumn) => {
      const matchingColumn = finalColumns.find((column) => column.id === sortColumn?.col?.id);
      if (!matchingColumn && sortColumn?.col) {
        finalColumns.push(sortColumn.col);
      }
    });
  }
  finalColumns.forEach((column) => {
    const colData = column;

    if (colData.isLookup) {
      select += setUpNestedLookup({
        lookupPath: colData.lookupPath,
        tableNamesForInner: tableNamesWithInnerFromFilters[colData.id],
        compositeKeysByTable,
        columnType: column?.type,
        aggregateFunctionConfig:
          column.cellConfig?.aggregateFunction && isCombinedAggregateFetch
            ? column.cellConfig?.aggregateFunction
            : undefined,
        isCombinedAggregate: isCombinedAggregateFetch
      });
    } else if (colData.isFormula) {
      if (colData.name) {
        // If column has name linked fetch it
        select += `${colData.name}, `;
      }
    } else {
      // ##HARDCODED: Add source_root_url, source_url, source_root_title, source_title to notes table
      if (
        baseTableName === "notes" &&
        !select.includes("source_root_url, source_url, source_root_title, source_title, ")
      ) {
        select += "source_root_url, source_url, source_root_title, source_title, ";
      }
      if (baseTableName === "files") {
        if (!select.includes("thumbnail_path")) select += "thumbnail_path,";
        if (!select.includes("metadata->")) select += "metadata->mimetype,";
        if (!select.includes("width")) select += "width,";
        if (!select.includes("height")) select += "height,";
      }

      if (baseTableName === "tiles" && !select.includes("public_id,")) {
        select += "public_id,";
      }
      if (
        colData.type === CellType.ADDRESS &&
        !select.includes("address1, address2, city, state, zip, latitude, longitude, ") &&
        baseTableName !== "lists_properties" // ##HARDCODED: Address fields are different for lists_properties
      ) {
        select += "address1, address2, city, state, zip, latitude, longitude, ";
      } else {
        if (
          isCombinedAggregateFetch &&
          colData.cellConfig?.aggregateFunction?.isCombinedAggregate &&
          colData.name &&
          colData.cellConfig?.aggregateFunction?.function
        ) {
          select += `${colData.name}.${colData.cellConfig?.aggregateFunction?.function}(), `;
        } else {
          // Column previously added and present
          if (!select.includes(`,${colData.name},`) && colData.name) {
            select += `${colData.name}, `;
          }
        }
      }
    }
  });
  const columnsHaveId = columns.find((column) => column.name === "id");
  const columnsHaveNonIdPrimaryKey = nonIDPrimaryKey
    ? columns.find((column) => column.name === nonIDPrimaryKey)
    : false;
  const columnsAreCombinedAggregate = columns.find(
    (column) => !!column.cellConfig?.aggregateFunction?.isCombinedAggregate
  );
  if (!columnsHaveId && !columnsAreCombinedAggregate && !DB_VIEWS.includes(baseTableName)) {
    if (nonIDPrimaryKey && !columnsHaveNonIdPrimaryKey) {
      select = `${nonIDPrimaryKey}, ${select}`;
    } else {
      select = "id," + select; // Add id to select if not already there
    }
  }
  return select.trim().slice(0, -1);
};

/**
 * If any column has filters linked, adds them to final query as filters
 * Assumes the select query has already been updated to include these columns
 * @param columns
 * @param supabaseSelectRef
 * Mutates supabaseSelectRef
 */
export const constructSupabaseColumnFilters = (
  columns: Array<TableColumnType>,
  tableName: string,
  supabaseSelectRef: any
) => {
  columns.forEach((column) => {
    const { lookupPath } = column;
    let hasLookupCols = false; // This check is to ensure only columns with complete lookup paths are included
    Object.keys(lookupPath || {}).forEach((lookupLevel) => {
      const { lookupColumns } = lookupPath?.[lookupLevel] || {};
      if (lookupColumns?.length) {
        hasLookupCols = true;
      }
    });
    if (column.lookupFilters?.length && hasLookupCols) {
      const updatedColLookupFilters = column.lookupFilters.map((filter) => {
        // Add lookup column label from the column lookup path
        // Lookup filter should always use the same label
        const firstLookupLevel = lookupPath?.["0"];
        // This condition ensures that the correct column label is used when adding column filter
        if (firstLookupLevel?.lookupColumnLabel && filter.filterLookupPath) {
          return {
            ...filter,
            filterLookupPath: {
              ...filter.filterLookupPath,
              ["0"]: {
                ...filter.filterLookupPath?.["0"],
                lookupColumnLabel: firstLookupLevel.lookupColumnLabel
              }
            }
          };
        }
        return filter;
      });

      constructSupabaseSlugFilter({ filters: updatedColLookupFilters }, tableName, supabaseSelectRef);
    }
  });
  return supabaseSelectRef;
};

/**
 * Filters applied to the tableName
 * Assumes the select query has already been updated to include these columns
 * @param columns
 * @param supabaseSelectRef
 * @param tableName
 * @param currentUser
 * Mutates supabaseSelectRef
 */
export const constructSupabaseSlugFilter = (
  filterOptions: TableViewType,
  tableName: string,
  supabaseSelectRef: any,
  currentUser?: User
) => {
  if (filterOptions?.filters?.length) {
    // Add check to see if multiple filters to same table
    const filterLookupTables: { [lookupTableName: string]: TableFilterType[] } = {
      single: [] // Holds all filter without a lookup table
    };
    filterOptions?.filters.forEach((filter) => {
      if (!!filter.column?.lookupPath?.[0]?.lookupTableName) {
        let finalFilterField = "";

        if (filter.isFilterColumnImageRecordType) {
          if (filter.column?.lookupPath?.[0].lookupType === LookupTypes.FOREIGN) {
            const { lookupTableName, lookupForeignKey, lookupColumnLabel } = filter.column.lookupPath[0];
            finalFilterField = lookupColumnLabel || lookupForeignKey || lookupTableName;
          } else if (filter.column?.lookupPath?.[0].lookupType === LookupTypes.JOIN) {
            const { lookupTableName, lookupForeignKey, lookupColumnLabel } = filter.column.lookupPath[1];
            finalFilterField = lookupColumnLabel || lookupForeignKey || lookupTableName;
          }
        } else {
          Object.keys(filter.column.lookupPath).forEach((lookupLevel) => {
            if (!filter.column || !filter.column.lookupPath) {
              return;
            }
            const { lookupTableName, lookupColumns, lookupForeignKey, lookupColumnLabel } =
              filter.column.lookupPath[lookupLevel];
            if (lookupColumns?.length) {
              finalFilterField +=
                tableName === lookupTableName
                  ? lookupColumns[0]
                  : `${lookupForeignKey || lookupTableName}${
                      lookupForeignKey && lookupColumns[0] === "id" && !lookupColumnLabel ? "!inner" : ""
                    }.${lookupColumns[0]}`;
            } else {
              finalFilterField += tableName === lookupTableName ? "" : `${lookupForeignKey || lookupTableName}.`;
            }
          });
        }

        if (finalFilterField) {
          if (!filterLookupTables[finalFilterField]) {
            filterLookupTables[finalFilterField] = [];
          }
          filterLookupTables[finalFilterField].push(filter);
        }
      } else {
        filterLookupTables.single.push(filter);
      }
    });

    // Run merge for multiple filters to same table
    const updatedFilters = constructMultiFilter(filterLookupTables);

    updatedFilters.forEach((filter) => {
      const {
        filterField,
        filterOperator,
        filterLookupPath,
        includeNull,
        filterGroup,
        isFilterTextSearch,
        isFilterColumnJoinTable,
        joinTableFilterColumn,
        isTagsJoinTable,
        useFilterLookupColumn,
        useColumnLabel,
        isFilterJSONColumn,
        useLikeOperator,
        isFromFilters
      } = filter;
      let isFilterColumnPeopleForeignKey = false;
      let finalFilterField = filterField;
      let isFilesTagColumn = false;
      let isFileTagNestedColumn = false;
      let orFilterValues: { filterString: string; orOptions: RecordItem } | undefined;
      let finalIsFilterColumnJoinTable = isFilterColumnJoinTable;

      if (!isEmpty(filterLookupPath)) {
        finalFilterField = "";
        if (filter.isFilterColumnImageRecordType) {
          if (filter.column?.lookupPath?.[0].lookupType === LookupTypes.FOREIGN) {
            const { lookupTableName, lookupForeignKey, lookupColumnLabel } = filter.column.lookupPath[0];
            finalFilterField = lookupColumnLabel || lookupForeignKey || lookupTableName;
          } else if (filter.column?.lookupPath?.[0].lookupType === LookupTypes.JOIN) {
            const { lookupTableName, lookupForeignKey, lookupColumnLabel } = filter.column.lookupPath[1];
            finalFilterField = lookupColumnLabel || lookupForeignKey || lookupTableName;
          }
        } else {
          const totalLookupLevels = Object.keys(filterLookupPath).length;
          Object.keys(filterLookupPath).forEach((lookupLevel) => {
            const { lookupTableName, lookupColumns, lookupForeignKey, lookupColumnLabel } =
              filterLookupPath[lookupLevel];
            // The lookupLevel === `${totalLookupLevels - 1}` check is to ensure we don't add lookup columns for any level but the last
            // This can happen in cases such as Product lookup, which has name in the first level, but is_featured in the last
            if (lookupColumns?.length && lookupLevel === `${totalLookupLevels - 1}`) {
              finalFilterField +=
                tableName === lookupTableName
                  ? lookupColumns[0]
                  : `${lookupColumnLabel || lookupForeignKey || lookupTableName}${
                      lookupForeignKey && lookupColumns[0] === "id" && !lookupColumnLabel ? "!inner" : ""
                    }.${lookupColumns[0]}`;
            } else {
              finalFilterField +=
                tableName === lookupTableName ? "" : `${lookupColumnLabel || lookupForeignKey || lookupTableName}.`;
            }
          });
          if (finalFilterField?.endsWith(".")) {
            finalFilterField = finalFilterField.slice(0, -1);
          }
        }
        if (
          filter.filterOperator &&
          [FILTER_OPERATOR.EMPTY, FILTER_OPERATOR.NOT_EMPTY].includes(filter.filterOperator as FILTER_OPERATOR) &&
          filter?.filterLookupPath?.["0"]?.lookupType === LookupTypes.JOIN
        ) {
          finalIsFilterColumnJoinTable = true;
        }
      }
      let finalFilterValue = getSupabaseFilterValue(filter);
      if (filter.column && filter.column?.isLookup) {
        const { column } = filter;
        isFilesTagColumn = isColumnFileTag(column);
        isFileTagNestedColumn = isColumnNestedFileTag(column);
        const { finalFilter, isPeopleForeignKey, finalLookupColumn, isForeignKey } = constructLookupFilter({
          lookupPath: column.lookupPath || {},
          type: column.type,
          useColumnLabel:
            (isTagsJoinTable && filterOperator === FILTER_OPERATOR.NOT_EMPTY) ||
            useFilterLookupColumn ||
            isFileTagNestedColumn ||
            useColumnLabel,
          columnHeader: column.header,
          isFilterColumnImageRecordType: filter.isFilterColumnImageRecordType,
          filterOperator: filterOperator as FILTER_OPERATOR
        });
        isFilterColumnPeopleForeignKey = isPeopleForeignKey || !!isForeignKey;
        finalFilterField =
          isPeopleForeignKey || filterOperator === FILTER_OPERATOR.EMPTY || column.type === CellType.DEAL // don't add id check for project cell type as we use the project_id foreign key
            ? finalFilter
            : useFilterLookupColumn || column.type === CellType.DATETIME
              ? `${finalFilter}.${finalLookupColumn}`
              : `${finalFilter}.id`;
        if (
          isFilesTagColumn &&
          joinTableFilterColumn &&
          ![FILTER_OPERATOR.EMPTY, FILTER_OPERATOR.NOT_EMPTY].includes(filterOperator as FILTER_OPERATOR)
        ) {
          finalFilterField = `${finalFilter}.${joinTableFilterColumn}`;
        }
      }
      const isFilterValidJsonBContains =
        filterOperator === FILTER_OPERATOR.CONTAINS &&
        filter.column?.dbType?.format === "jsonb" &&
        typeof finalFilterValue === "string" &&
        finalFilterValue?.includes(".") &&
        !finalFilterValue?.endsWith(".");

      const isFilterJsonBEquals =
        filterOperator === FILTER_OPERATOR.EQUALS &&
        filter.column?.dbType?.format === "jsonb" &&
        typeof finalFilterValue === "string" &&
        !!finalFilterValue?.includes(".") &&
        !(finalFilterValue?.endsWith(".") || finalFilterValue?.endsWith(":")) &&
        !!finalFilterValue?.includes(":");
      // Don't change filterfield if it is a view filter
      // filterField already has the right column name
      if (filter.isViewFilter) {
        finalFilterField = filter.filterField;
      }
      const isColumnIDFilter = finalFilterField?.endsWith(".id");
      if (
        (!filterOperator ||
          filterOperator === FILTER_OPERATOR.EQUALS ||
          (filterOperator === FILTER_OPERATOR.CUSTOM_RECORD && finalFilterValue)) &&
        !isFilterTextSearch
      ) {
        if (isFilterJsonBEquals) {
          if (finalFilterValue) {
            const valueParts = (finalFilterValue as string).split(":");
            const finalFilterFields = valueParts[0]?.split(".");
            let jsonFilterField = filterField;
            finalFilterFields?.forEach((field, index) => {
              if (index === finalFilterFields?.length - 1) {
                jsonFilterField += `->>${field}`;
              } else {
                jsonFilterField += `->${field}`;
              }
            });
            supabaseSelectRef = supabaseSelectRef.eq(jsonFilterField, valueParts[1]);
          }
        } else {
          if (isFilterJSONColumn) {
            supabaseSelectRef = supabaseSelectRef.containedBy(finalFilterField, finalFilterValue);
          } else {
            supabaseSelectRef = supabaseSelectRef.eq(finalFilterField, finalFilterValue);
          }
        }
      } else if (filterOperator === FILTER_OPERATOR.OR) {
        if (filterGroup?.length) {
          orFilterValues = constructORFilterFromGroup(
            filterGroup,
            filter.id,
            !!(
              filter.column?.dbType?.format === "boolean" ||
              filter.column?.type === CellType.BOOLEAN ||
              filter.filterDbType === "boolean"
            ),
            !!(filter.column?.dbType?.format === "numeric" || filter.filterDbType === "numeric")
          );
          finalFilterField = orFilterValues?.filterString || "";
          supabaseSelectRef = supabaseSelectRef.or(finalFilterField, { ...(orFilterValues?.orOptions || {}) });
        }
      } else if (FILTER_OPERATOR.NOT_EQUALS === filterOperator && finalFilterField) {
        // Include null if set and filter is not a lookup
        if ((includeNull || isFromFilters) && !filter.column?.isLookup && !filterLookupPath) {
          supabaseSelectRef = supabaseSelectRef.or(
            `${finalFilterField}.is.null,${finalFilterField}.neq.${finalFilterValue}`
          );
        } else {
          supabaseSelectRef = supabaseSelectRef.neq(finalFilterField, finalFilterValue);
        }
      } else if (filterOperator === FILTER_OPERATOR.EMPTY) {
        supabaseSelectRef =
          !isColumnIDFilter &&
          !isFilterColumnPeopleForeignKey &&
          !isFilesTagColumn &&
          !isFileTagNestedColumn &&
          !finalIsFilterColumnJoinTable &&
          !isFilterNonTextType(filter)
            ? supabaseSelectRef.or(`${finalFilterField}.is.null,${finalFilterField}.eq.`)
            : supabaseSelectRef.is(finalFilterField, null);
      } else if (filterOperator === FILTER_OPERATOR.NOT_EMPTY) {
        supabaseSelectRef = supabaseSelectRef.not(finalFilterField, "is", null);
        if (
          !isColumnIDFilter &&
          !isFilterColumnPeopleForeignKey &&
          !finalIsFilterColumnJoinTable &&
          !isFilterNonTextType(filter)
        ) {
          supabaseSelectRef = supabaseSelectRef.neq(finalFilterField, ""); // No empty string
        }
      } else if (filterOperator === FILTER_OPERATOR.IN && (finalFilterValue as RecordItem[])?.length) {
        supabaseSelectRef = supabaseSelectRef.in(finalFilterField, finalFilterValue);
      } else if (
        filterOperator === FILTER_OPERATOR.NOT_IN &&
        finalFilterValue &&
        (finalFilterValue as RecordItem[])?.length
      ) {
        if (includeNull && !filter.column?.isLookup && !filterLookupPath) {
          supabaseSelectRef = supabaseSelectRef.or(
            `${finalFilterField}.not.in.(${(finalFilterValue as RecordItem[]).join(",")}),${finalFilterField}.is.null`
          );
        } else {
          supabaseSelectRef = supabaseSelectRef.not(
            finalFilterField,
            "in",
            `(${(finalFilterValue as RecordItem[]).join(",")})`
          );
        }
      } else if ((filterOperator === FILTER_OPERATOR.CONTAINS || isFilterTextSearch) && finalFilterValue) {
        if (isFilterValidJsonBContains) {
          const valueParts = typeof finalFilterValue === "string" ? finalFilterValue.split(".") : [];

          if (valueParts?.length === 2) {
            supabaseSelectRef = supabaseSelectRef.contains(finalFilterField, { [valueParts[0]]: valueParts[1] });
          }
        }
        if (filter?.column?.isTextArray || filter?.filterDbType === "text[]") {
          const valueParts = typeof finalFilterValue === "string" ? finalFilterValue.split(",") : [];
          supabaseSelectRef = supabaseSelectRef.contains(finalFilterField, valueParts);
        } else {
          if (useLikeOperator) {
            supabaseSelectRef = supabaseSelectRef.ilike(finalFilterField, `%${finalFilterValue}%`);
          } else {
            const valueParts = typeof finalFilterValue === "string" ? finalFilterValue.split(" ") : [];
            if (valueParts.length > 1) {
              finalFilterValue = valueParts.filter((v) => `'${v}'`).join(" | ");
            } else {
              finalFilterValue = `${finalFilterValue}:*`;
            }
            // Text search
            supabaseSelectRef = supabaseSelectRef.textSearch(finalFilterField, finalFilterValue);
          }
        }
      } else if (
        (filterOperator === FILTER_OPERATOR.CONTAINED_BY || isFilterTextSearch) &&
        finalFilterValue &&
        (filter?.column?.isTextArray || filter?.filterDbType === "text[]")
      ) {
        const valueParts = typeof finalFilterValue === "string" ? finalFilterValue.split(",") : finalFilterValue;
        // Also add check for empty array
        supabaseSelectRef = supabaseSelectRef
          .containedBy(finalFilterField, valueParts)
          .not(finalFilterField, "eq", "{}");
      } else if ((filterOperator === FILTER_OPERATOR.DOES_NOT_CONTAIN || isFilterTextSearch) && finalFilterValue) {
        if (filter.column?.isTextArray || filter?.filterDbType === "text[]") {
          const valueParts = typeof finalFilterValue === "string" ? finalFilterValue.split(",") : finalFilterValue;
          if (includeNull && !filter.column?.isLookup && !filterLookupPath) {
            supabaseSelectRef = supabaseSelectRef.or(
              `${finalFilterField}.not.cs.{${(valueParts as string[]).join(",")}}, ${finalFilterField}.is.null`
            );
          } else {
            supabaseSelectRef = supabaseSelectRef.not(
              finalFilterField,
              "cs",
              `{${(valueParts as string[]).join(",")}}`
            );
          }
        } else {
          const valueParts = typeof finalFilterValue === "string" ? finalFilterValue.split(" ") : [];
          if (valueParts.length > 1) {
            finalFilterValue = valueParts.filter((v) => `'!${v}'`).join(" | ");
          } else {
            finalFilterValue = `!${finalFilterValue}`;
          }
          if (includeNull && !filter.column?.isLookup && !filterLookupPath) {
            supabaseSelectRef = supabaseSelectRef.or(
              `${finalFilterField}.not.ilike.%${finalFilterValue.replace("!", "")}%, ${finalFilterField}.is.null`
            );
          } else {
            // Text search
            supabaseSelectRef = supabaseSelectRef.textSearch(finalFilterField, finalFilterValue);
          }
        }
      } else if (filterOperator === FILTER_OPERATOR.CURRENT_USER && currentUser?.user_id) {
        supabaseSelectRef = supabaseSelectRef.eq(finalFilterField, currentUser.user_id);
      } else if (filterOperator === FILTER_OPERATOR.GREATER_THAN) {
        supabaseSelectRef = supabaseSelectRef.gt(finalFilterField, finalFilterValue);
      } else if (filterOperator === FILTER_OPERATOR.GREATER_THAN_EQUALS) {
        supabaseSelectRef = supabaseSelectRef.gte(finalFilterField, finalFilterValue);
      } else if (filterOperator === FILTER_OPERATOR.LESS_THAN) {
        supabaseSelectRef = supabaseSelectRef.lt(finalFilterField, finalFilterValue);
      } else if (filterOperator === FILTER_OPERATOR.LESS_THAN_EQUALS) {
        supabaseSelectRef = supabaseSelectRef.lte(finalFilterField, finalFilterValue);
      } else if (
        [FILTER_OPERATOR.CURRENT_DEAL, FILTER_OPERATOR.CURRENT_RECORD].includes(filterOperator as FILTER_OPERATOR) &&
        finalFilterValue
      ) {
        supabaseSelectRef = supabaseSelectRef.eq(finalFilterField, finalFilterValue);
      }
    });
  }
  return supabaseSelectRef;
};

// This function is used to return only the properties defined in the
// foreign lookup columns. This is used to avoid sending the entire
// object to finalData.
const buildDataForForeignKeysColumns = ({
  data,
  lookupColumns,
  foreignLookupColumns,
  propName,
  previousData,
  isFileType = false
}: {
  data: RecordItem[];
  lookupColumns: string[];
  foreignLookupColumns: LookupEntry[];
  propName?: string;
  previousData?: RecordItem[];
  isFileType?: boolean;
}) => {
  return data.map((item, index) => {
    const finalItem: RecordItem = {};

    lookupColumns.forEach((col) => {
      const foreignLookupColumn = foreignLookupColumns.find((fkCol) => fkCol.lookupForeignKey === col);

      if (foreignLookupColumn) {
        foreignLookupColumn.lookupColumns?.forEach((lookupCol) => {
          finalItem[lookupCol] = propName ? item?.[propName]?.[col]?.[lookupCol] : item?.[col]?.[lookupCol];
        });
      } else {
        finalItem[col] = item[col];
      }
    });

    if (isFileType) {
      finalItem["fileId"] = item?.id;
    }

    if (previousData) {
      return {
        ...previousData[index],
        ...finalItem
      };
    }

    return finalItem;
  });
};

export const readCellDealWithFiles = ({
  lookupPath,
  recordData,
  recordTypesData,
  colData
}: {
  lookupPath: { [level: string]: LookupEntry };
  recordData: RecordItem;
  recordTypesData?: ApiRecordType;
  colData: TableColumnType;
}): RecordItem | null => {
  let allLookupColumnsFlat: RecordItem | null = null;
  const propertyChainWithLookupCols: Array<{ propName: string; propTableName?: string; lookupColumns?: string[] }> = [];
  Object.keys(lookupPath || {}).forEach((lookupLevel) => {
    if (lookupPath[lookupLevel].lookupColumns?.length) {
      propertyChainWithLookupCols.push({
        propName:
          lookupPath[lookupLevel].lookupColumnLabel ||
          lookupPath[lookupLevel].lookupForeignKey ||
          lookupPath[lookupLevel].lookupTableName,
        propTableName: lookupPath[lookupLevel].lookupTableName,
        lookupColumns: lookupPath[lookupLevel].lookupColumns
      });
    } else {
      propertyChainWithLookupCols.push({
        propName:
          lookupPath[lookupLevel].lookupColumnLabel ||
          lookupPath[lookupLevel].lookupForeignKey ||
          lookupPath[lookupLevel].lookupTableName
      });
    }
  });

  // Must have places in lookup path for this
  if (
    propertyChainWithLookupCols?.length &&
    propertyChainWithLookupCols.find(
      (propVal: { propName: string; propTableName?: string }) => propVal.propTableName === "properties"
    )
  ) {
    let recordObjectNested = recordData;

    if (isEmpty(recordObjectNested)) {
      return null;
    }
    try {
      let hasProjectFilesInLookup = false;
      for (let i = 0; i < propertyChainWithLookupCols.length; i++) {
        const propChain = propertyChainWithLookupCols[i];
        if (propChain.propTableName === "properties" && recordObjectNested) {
          // Handle this specially to pick path from nested value and move up to final data
          if (Array.isArray(recordObjectNested)) {
            const updatedRecordObjNested: RecordItem[] = [];
            allLookupColumnsFlat = recordObjectNested.map((item) => {
              const finalItem = item?.[propChain.propName];
              const dealFields = omit(item, ["id", propChain.propName]);
              updatedRecordObjNested.push(finalItem);
              if (finalItem) {
                const finalObj: RecordItem = {};
                PLACES_ADDRESS_FIELDS.forEach((addressField: string) => {
                  finalObj[addressField] = finalItem?.[addressField];
                });
                return {
                  dealId: item?.id,
                  ...finalObj,
                  ...finalItem,
                  ...dealFields
                };
              }
            });
            recordObjectNested = updatedRecordObjNested;
          } else {
            const dealId = recordObjectNested?.id;
            const dealFields = omit(recordObjectNested, ["id", propChain.propName]);
            recordObjectNested = recordObjectNested?.[propChain.propName];
            if (!isEmpty(recordObjectNested)) {
              const finalObj: RecordItem = {};
              PLACES_ADDRESS_FIELDS.forEach((addressField: string) => {
                finalObj[addressField] = recordObjectNested?.[addressField];
              });
              allLookupColumnsFlat = {
                ...(recordObjectNested || {}),
                ...finalObj,
                ...dealFields,
                dealId
              };
            }
          }
        } else if (propChain.propTableName === "deals_files" && recordObjectNested) {
          hasProjectFilesInLookup = true;
          // Pick first project
          if (Array.isArray(recordObjectNested)) {
            recordObjectNested = recordObjectNested?.[0];
          }
          // Multiple projects
          if (Array.isArray(recordObjectNested) && Array.isArray(allLookupColumnsFlat)) {
            allLookupColumnsFlat = allLookupColumnsFlat.map((allData: RecordItem, index: number) => {
              return {
                ...allData,
                path: !!recordObjectNested?.[index]?.projects_files?.length
                  ? findFeaturedOrFirstFromJoinFiles(recordObjectNested?.[index]?.projects_files)?.path
                  : ""
              };
            });
          } else {
            allLookupColumnsFlat = {
              ...(allLookupColumnsFlat || {}),
              path: !!(recordObjectNested as RecordItem)?.projects_files?.length
                ? findFeaturedOrFirstFromJoinFiles((recordObjectNested as RecordItem)?.projects_files)?.path
                : ""
            };
          }

          break;
        } else if (recordObjectNested) {
          if (Array.isArray(recordObjectNested)) {
            recordObjectNested = recordObjectNested.map((item) => {
              return item?.[propChain.propName] || {};
            });
          } else {
            recordObjectNested = recordObjectNested[propChain.propName];
          }
        }
      }
      // #HARDCODED check for nested foreign lookup columns for featured_file_id
      // This is a case when fetched a Deal column for the deals table
      if (
        colData?.lookupPath?.["1"]?.foreignLookupColumns?.length &&
        colData?.lookupPath?.["1"]?.foreignLookupColumns?.find((lcol) => lcol.lookupForeignKey === "featured_file_id")
      ) {
        const dealData =
          recordData?.[
            colData.lookupPath?.["0"]?.lookupColumnLabel ||
              colData.lookupPath?.["0"]?.lookupForeignKey ||
              colData.lookupPath?.["0"]?.lookupTableName
          ];
        const featuredFileData =
          dealData?.[colData.lookupPath?.["1"]?.lookupForeignKey || colData.lookupPath?.["1"]?.lookupTableName];
        if (featuredFileData) {
          const imgData = featuredFileData?.featured_file_id;
          if (imgData?.path) {
            allLookupColumnsFlat = {
              ...allLookupColumnsFlat,
              path: imgData?.path
            };
          }
        }
      }

      if (
        recordData?.[getImageColumnLabel(colData)] &&
        recordTypesData?.image_column?.lookup_path &&
        !hasProjectFilesInLookup
      ) {
        const imgCol = getImageColumnForRecordType(recordTypesData, colData);
        const imgData = imgCol ? readLookupFields(imgCol, recordData) : null;
        if (imgData?.path) {
          allLookupColumnsFlat = {
            ...allLookupColumnsFlat,
            path: imgData?.path
          };
        }
      }

      return allLookupColumnsFlat || {};
    } catch (err) {
      console.log("@@ ERR reading join files ", err);
    }
  }
  return {};
};

export const readCellJSONValueFromRecord = ({
  column,
  recordData
}: {
  column: TableColumnType;
  recordData: RecordItem;
}) => {
  if (column && recordData && isObject(recordData)) {
    if (column.isLookup) {
      const propNames = getColumnOptionsLookupPropAndColumnName(column);

      if (Array.isArray(propNames.finalPropName)) {
        let finalValue: RecordItem = recordData;

        propNames.finalPropName.forEach((prop: string) => {
          finalValue = finalValue?.[prop] as RecordItem;
        });

        return finalValue?.[propNames?.lookupCols];
      } else if (typeof propNames.finalPropName === "string") {
        return (recordData as RecordItem)?.[propNames.finalPropName]?.[propNames.lookupCols];
      }
      return;
    } else {
      return (recordData as RecordItem)?.[column.name];
    }
  }

  return;
};

const getPropValsFromLookupPath = ({
  propertyChainWithLookupCols,
  recordData,
  isLookupBaseJoinTable,
  isLvl2RecordTypeForeignKey
}: {
  propertyChainWithLookupCols: RecordItem[];
  recordData: RecordItem;
  isLookupBaseJoinTable?: boolean;
  isLvl2RecordTypeForeignKey?: boolean;
}) => {
  let recordObjectNested = recordData;
  const propVals: RecordItem = {};
  const joinTableRecordsFirstLevel: RecordItem[] = [];
  for (let i = 0; i < propertyChainWithLookupCols.length; i++) {
    const propChain = propertyChainWithLookupCols[i];
    if (Array.isArray(recordObjectNested)) {
      recordObjectNested = recordObjectNested.map((item) => {
        if (propChain.lookupColumns?.length) {
          propChain.lookupColumns.forEach((key: string) => {
            // skip "id" lookup column as it is  added in the first level
            if (item?.[propChain.propName]?.[key] && key !== "id") {
              propVals[key] = item?.[propChain.propName]?.[key];
            }
          });
          // add id only for the first level
          if (item?.[propChain.propName]?.["id"] && i === 0) {
            propVals["id"] = item?.[propChain.propName]?.["id"];
          }
        }
        if (i === 1 && isLookupBaseJoinTable) {
          joinTableRecordsFirstLevel.push(item);
        }

        return item?.[propChain.propName] || {};
      });
    } else {
      recordObjectNested = recordObjectNested?.[propChain.propName];
      if (propChain.lookupColumns?.length) {
        propChain.lookupColumns.forEach((key: string) => {
          // skip "id" lookup column as it is added in the first level
          if (recordObjectNested?.[key] && key !== "id") {
            propVals[key] = recordObjectNested?.[key];
          }
        });
        // add id only for the first level
        if (
          recordObjectNested?.["id"] &&
          ((!isLvl2RecordTypeForeignKey && i === 0) || (isLvl2RecordTypeForeignKey && i === 1))
        ) {
          propVals["id"] = recordObjectNested?.["id"];
        }
      }
    }
  }
  return propVals;
};

export const readCellFilesLookupFields = ({
  lookupPath,
  recordData,
  isLookupBaseJoinTable,
  isMultiple
}: {
  lookupPath: { [level: string]: LookupEntry };
  recordData: RecordItem;
  recordTypesData?: ApiRecordType;
  isLookupBaseJoinTable?: boolean;
  isMultiple?: boolean;
}) => {
  const propertyChainWithLookupCols: Array<{ propName: string; lookupColumns?: string[] }> = [];
  const lookupTableNames: string[] = [];

  Object.keys(lookupPath || {}).forEach((lookupLevel) => {
    if (lookupPath[lookupLevel].lookupColumns?.length) {
      propertyChainWithLookupCols.push({
        propName:
          lookupPath[lookupLevel].lookupColumnLabel ||
          lookupPath[lookupLevel].lookupForeignKey ||
          lookupPath[lookupLevel].lookupTableName,
        lookupColumns: lookupPath[lookupLevel].lookupColumns
      });
    } else {
      propertyChainWithLookupCols.push({
        propName:
          lookupPath[lookupLevel].lookupColumnLabel ||
          lookupPath[lookupLevel].lookupForeignKey ||
          lookupPath[lookupLevel].lookupTableName
      });
    }
    lookupTableNames.push(lookupPath[lookupLevel].lookupTableName);
  });
  // This is to manage the featured image column
  if (lookupTableNames?.includes("files")) {
    let propVals: RecordItem | RecordItem[] = {};
    if (isMultiple) {
      const firstPropChainLevel = propertyChainWithLookupCols.shift();
      const finalRecordData = recordData?.[firstPropChainLevel?.propName || ""];
      if (Array.isArray(finalRecordData)) {
        propVals = finalRecordData?.map((item: RecordItem) => {
          return getPropValsFromLookupPath({
            propertyChainWithLookupCols,
            recordData: item,
            isLookupBaseJoinTable,
            isLvl2RecordTypeForeignKey: lookupPath?.["0"]?.isLvl2RecordTypeForeignKey
          });
        });
      }
    } else {
      propVals = getPropValsFromLookupPath({
        propertyChainWithLookupCols,
        recordData,
        isLookupBaseJoinTable,
        isLvl2RecordTypeForeignKey: lookupPath?.["0"]?.isLvl2RecordTypeForeignKey
      });
    }
    return propVals || {};
  }
  return {};
};

export const readLookupFields = (
  colData: TableColumnType,
  recordData: RecordItem,
  extendedSchema?: ExtendedSchema,
  recordTypesData?: ApiRecordType[],
  basePageTableName?: string
) => {
  const { lookupPath } = colData;

  if (!recordData || !lookupPath) return null;

  let finalData: RecordItem | RecordItem[] = {};
  const nestedProperties: string[] = [];

  const tableProps = extendedSchema?.[lookupPath?.["0"]?.lookupTableName];
  let isFileType = false;
  let isNoteType = false;
  let isCompositeKeyWithID = false;
  if (tableProps?.compositePk?.length) {
    const fileCol = tableProps?.compositePk?.find((item: CompositePKey) => item.table === "files");
    const noteCol = tableProps?.compositePk?.find((item: CompositePKey) => item.table === "notes");
    const hasIdInCompositeKey = tableProps?.compositePk?.find((item: CompositePKey) => item.attributeId === "id");

    if (fileCol) {
      isFileType = true;
    }
    if (hasIdInCompositeKey) {
      isCompositeKeyWithID = true;
    }
    if (noteCol) {
      isNoteType = true;
    }
  }

  if (colData.type === CellType.JSON) {
    return readCellJSONValueFromRecord({ column: colData as TableColumnType, recordData });
  }

  let isBasePageRecordTypePage = false;

  if (colData.type === CellType.PEOPLE) {
    const peopleRecordType = recordTypesData?.find((item) => item?.type === CellType.PEOPLE);
    // The lookup path level check is to avoid reading record type image column for older columns
    const imgCol =
      peopleRecordType?.page_id &&
      peopleRecordType?.image_column?.lookup_path &&
      Object.keys(lookupPath || {}).length <= 2
        ? getImageColumnForRecordType(peopleRecordType, colData, extendedSchema)
        : undefined;
    const finalLookupPath = imgCol?.lookupPath || lookupPath;
    const isLookupBaseJoinTable = !!extendedSchema?.[finalLookupPath?.["0"]?.lookupTableName]?.compositePk?.length;
    isBasePageRecordTypePage = !!basePageTableName && peopleRecordType?.page?.table_name === basePageTableName;
    if (imgCol) {
      return readCellFilesLookupFields({
        lookupPath: finalLookupPath,
        recordData,
        recordTypesData: peopleRecordType,
        isLookupBaseJoinTable,
        isMultiple: colData.isMultiple
      });
    }
  }

  if (colData.type === CellType.COMPANY) {
    const companyRecordType = recordTypesData?.find((item) => item?.type === CellType.COMPANY);
    const imgCol =
      companyRecordType?.page_id && Object.keys(lookupPath || {}).length <= 2
        ? getImageColumnForRecordType(companyRecordType, colData, extendedSchema)
        : undefined;
    const finalLookupPath = imgCol?.lookupPath || lookupPath;
    const isLookupBaseJoinTable = !!extendedSchema?.[finalLookupPath?.["0"]?.lookupTableName]?.compositePk?.length;
    isBasePageRecordTypePage = !!basePageTableName && companyRecordType?.page?.table_name === basePageTableName;
    if (!basePageTableName || (!!basePageTableName && companyRecordType?.page?.table_name !== basePageTableName))
      return readCellFilesLookupFields({
        lookupPath: finalLookupPath,
        recordData,
        recordTypesData: companyRecordType,
        isLookupBaseJoinTable
      });
  }

  if (colData.type === CellType.DEAL) {
    const dealRecordType = recordTypesData?.find((item) => item?.type === CellType.DEAL);
    return readCellDealWithFiles({ lookupPath, recordData, recordTypesData: dealRecordType, colData });
  }

  if (colData.type === CellType.PRODUCT) {
    const productRecordType = recordTypesData?.find((item) => item?.type === CellType.PRODUCT);
    const imgCol =
      productRecordType?.page_id && Object.keys(lookupPath || {}).length <= 2
        ? getImageColumnForRecordType(productRecordType, colData, extendedSchema)
        : undefined;
    const finalLookupPath = imgCol?.lookupPath || lookupPath;
    const isLookupBaseJoinTable = !!extendedSchema?.[finalLookupPath?.["0"]?.lookupTableName]?.compositePk?.length;
    return readCellFilesLookupFields({
      lookupPath: finalLookupPath,
      recordData,
      recordTypesData: productRecordType,
      isLookupBaseJoinTable
    });
  }

  // This is a read only cell, so we return data
  // in the generic format at all times
  if (colData.type === CellType.GENERIC_CELL) {
    return getGenericCellValuesFromRecord({ column: colData, recordData, recordTypesData, extendedSchema });
  }

  try {
    Object.keys(lookupPath || {}).forEach((lookupLevel) => {
      const {
        lookupTableName = "",
        lookupColumns = [],
        lookupType = "",
        lookupForeignKey = "",
        lookupColumnLabel = "",
        foreignLookupColumns = []
      } = lookupPath?.[lookupLevel] ?? {};
      const isFilesTagColumn = lookupTableName === "files_tags";
      // ##HARDCODED: Special cases for files and notes
      const finalLookupColumns = Array.from(new Set(["id", ...(lookupColumns || [])])).concat(
        isFileType || lookupTableName === "files" ? ["file_type", "name", "thumbnail_path", "mimetype", "path"] : [],
        isNoteType || lookupTableName === "notes"
          ? ["source_root_title", "source_root_url", "source_url", "source_title"]
          : [],
        isFilesTagColumn ? ["tag_id", "file_id"] : []
      );

      if (lookupLevel === "0" && colData.type === CellType.SPACES) {
        const level0IsJoinTable = !!extendedSchema?.[lookupTableName]?.compositePk?.length;
        const columnOptionsLookupTableName = colData?.columnOptionsLookUp?.["0"]?.lookupTableName;
        const columnOptionsLevel0IsJoinTable =
          columnOptionsLookupTableName && !!extendedSchema?.[columnOptionsLookupTableName]?.compositePk?.length;

        const propName: string = lookupColumnLabel
          ? lookupColumnLabel
          : lookupType === LookupTypes.FOREIGN
            ? lookupForeignKey
            : lookupTableName;

        if (Array.isArray(recordData[propName]) && level0IsJoinTable && columnOptionsLevel0IsJoinTable) {
          finalData = recordData[propName].map((item: RecordItem) => {
            return {
              ...item,
              joinTableId: item.id
            };
          });
        } else if (level0IsJoinTable && columnOptionsLevel0IsJoinTable) {
          finalData = {
            ...finalData,
            joinTableId: recordData[propName]?.id
          };
        }
      }

      if (finalLookupColumns?.length) {
        const propName: string = lookupColumnLabel
          ? lookupColumnLabel
          : lookupType === LookupTypes.FOREIGN
            ? lookupForeignKey
            : lookupTableName;
        let nestedObject: RecordItem | RecordItem[] = {};
        if (nestedProperties.length) {
          nestedProperties.forEach((propName: string, propIdx: number) => {
            if (propIdx === 0) {
              nestedObject = recordData?.[propName];
            } else {
              const currentNestedObject = nestedObject;
              if (Array.isArray(currentNestedObject)) {
                const updatedNestedObj = currentNestedObject.map((obj: RecordItem) => obj?.[propName]);
                nestedObject = updatedNestedObj;
              } else {
                nestedObject = currentNestedObject?.[propName];
              }
            }
          });
        } else {
          nestedObject = recordData;
        }

        if (!isEmpty(nestedObject)) {
          const currentFinalDataIsNotArray = !Array.isArray(finalData);
          const nestedObjectIsArray = Array.isArray(nestedObject) || Array.isArray(nestedObject[propName]);
          // If finalData is empty, or if finalData is not an array and
          // nestedObject is an array we need to pass the actual finalData to
          // each object in the array of nested objects
          if (isEmpty(finalData) || (currentFinalDataIsNotArray && nestedObjectIsArray)) {
            if (Array.isArray(nestedObject)) {
              if (foreignLookupColumns?.length && foreignLookupColumns?.every((item) => item?.lookupColumns?.length)) {
                finalData = buildDataForForeignKeysColumns({
                  data: nestedObject,
                  lookupColumns: finalLookupColumns,
                  foreignLookupColumns,
                  propName
                });
              } else {
                finalData = nestedObject
                  .map((obj: RecordItem) => {
                    const finalObj = pick(obj?.[propName], finalLookupColumns);
                    if (isCompositeKeyWithID) {
                      finalObj.compositeId = obj?.id;
                    }
                    return isEmpty(finalObj) ? null : finalObj;
                  })
                  .filter(Boolean);
              }
            } else if (Array.isArray(nestedObject[propName])) {
              if (foreignLookupColumns?.length && foreignLookupColumns?.every((item) => item?.lookupColumns?.length)) {
                finalData = buildDataForForeignKeysColumns({
                  data: nestedObject[propName],
                  lookupColumns: finalLookupColumns,
                  foreignLookupColumns
                });
              } else {
                // If the final data is an object, we add the previous data to
                // the final collection
                const dataToAdd = isObject(finalData) ? finalData : {};

                finalData = nestedObject[propName]
                  .map((obj: RecordItem) => {
                    const finalObj = pick(obj, finalLookupColumns);
                    return isEmpty(finalObj)
                      ? null
                      : {
                          ...finalObj,
                          ...dataToAdd
                        };
                  })
                  .filter(Boolean);
              }
            } else {
              finalData = pick(nestedObject[propName], finalLookupColumns);
            }
          } else if (Array.isArray(finalData)) {
            if (foreignLookupColumns?.length && foreignLookupColumns?.every((item) => item?.lookupColumns?.length)) {
              finalData = buildDataForForeignKeysColumns({
                data: nestedObject as RecordItem[],
                lookupColumns: finalLookupColumns,
                foreignLookupColumns,
                propName,
                previousData: finalData,
                isFileType
              });
            } else {
              finalData = finalData
                .map((obj: RecordItem, index) => {
                  const finalDataItem = {
                    ...obj,
                    ...(pick((nestedObject as RecordItem[])?.[index]?.[propName], finalLookupColumns) || {})
                  };
                  if (isCompositeKeyWithID && !finalDataItem.compositeId) {
                    finalDataItem.compositeId = obj?.id;
                  }
                  if (isFileType) {
                    finalDataItem["fileId"] = (nestedObject as RecordItem[])?.[index]?.[propName]?.id;
                  }

                  return isEmpty(finalDataItem) ? null : finalDataItem;
                })
                .filter(Boolean);
            }
          } else {
            finalData = {
              ...finalData,
              // @ts-ignore
              ...nestedObject[propName]
            };

            if (isFileType) {
              finalData = {
                ...finalData,
                // @ts-ignore
                fileId: nestedObject[propName]?.id
              };
            }
          }

          // ##HARDCODED: Special case for record type column on base table of record type, for example
          // Parent company on companies table
          // TODO: Make this dynamic
          if (
            isBasePageRecordTypePage &&
            colData.type === CellType.COMPANY &&
            (nestedObject as RecordItem)?.name &&
            (finalData as RecordItem)?.path
          ) {
            // We need to pick ID for company and not the file data
            const lookupColLabel = lookupPath?.["0"]?.lookupColumnLabel || "";
            const mainRecordProps = recordData?.[lookupColLabel];
            if (mainRecordProps?.id) {
              finalData = {
                ...finalData,
                id: mainRecordProps?.id
              };
            }
            finalData = {
              ...finalData,
              name: (nestedObject as RecordItem)?.name
            };
          }
        }
      }

      nestedProperties.push(lookupColumnLabel ? lookupColumnLabel : lookupForeignKey || lookupTableName);
    });

    // When the base table name is projects_spaces and the lookup table name is
    // spaces we need to add the nickname to the final data
    if (colData.type === CellType.SPACES) {
      if (!finalData.nickname && recordData?.nickname) {
        finalData.nickname = recordData?.nickname;
      }
    }

    return finalData as RecordItem | RecordItem[];
  } catch (err) {
    console.log("Error reading lookup fields", err);
    return null;
  }
};

export const readLookupFieldsForCellGroups = (
  colData: TableColumnType,
  recordData: RecordItem,
  extendedSchema?: ExtendedSchema,
  recordTypesData?: ApiRecordType[],
  basePageTableName?: string
) => {
  const data = readLookupFields(colData, recordData, extendedSchema, recordTypesData, basePageTableName);
  if (isEmpty(data)) return null;

  try {
    if (!colData.type || colData.type === CellType.TEXT) {
      if (Array.isArray(data)) {
        let finalText = "";
        // For lookup column return the object the selection of the right prop is being handled in the cell
        if (colData.isLookup) {
          return data;
        }
        data.forEach((dataItem) => {
          Object.keys(dataItem).forEach((key) => {
            finalText += `${dataItem[key]}, `;
          });
        });
        return finalText.trim().slice(0, -1);
      } else {
        // For lookup column return the object the selection of the right prop is being handled in the cell
        if (colData.isLookup) {
          const finalPropName = getColumnOptionsLookupPropAndColumnName(colData, false, true);
          // @ts-ignore
          if (finalPropName.lookupCols && data?.[finalPropName.lookupCols]) {
            return data;
          } else {
            return null;
          }
        }
        let finalText = "";
        Object.keys(data).forEach((key) => {
          finalText += `${data[key]}, `;
        });

        return finalText.trim().slice(0, -1);
      }
    } else if (colData.type === CellType.PEOPLE) {
      return !Array.isArray(data) ? [data] : data;
    } else {
      return data;
    }
  } catch (err) {
    console.log("Error transforming lookup fields for cell groups", err);
    return null;
  }
};

/**
 *  Returns the relevant column name to allow picking from data
 *  Mainly used by column options fetch
 */
export const getColumnOptionsLookupPropAndColumnName = (
  column: TableColumnType,
  keepLevelZero = false,
  useLookupPath?: boolean
) => {
  const { columnOptionsLookUp, lookupPath } = column;
  if (isEmpty(columnOptionsLookUp) && isEmpty(lookupPath)) {
    return { lookupCols: "", finalPropName: "" };
  }
  const allLookupCols: string[] = [];
  const finalLookupPath =
    isEmpty(columnOptionsLookUp) ||
    columnOptionsLookUp?.["0"]?.isCustomSelectOptions ||
    columnOptionsLookUp?.["0"]?.isLookupEnumOptions ||
    useLookupPath
      ? lookupPath
      : columnOptionsLookUp;
  if (Object.keys(finalLookupPath || {}).length === 1) {
    const level0 = finalLookupPath?.["0"];
    return {
      lookupCols: level0?.lookupDisplayColumn ? level0?.lookupDisplayColumn : level0?.lookupColumns?.[0] || "",
      finalPropName: level0?.lookupColumnLabel || level0?.lookupForeignKey || level0?.lookupTableName,
      allLookupCols: level0?.lookupColumns || []
    };
  }
  const updatedLookupLevels = { ...finalLookupPath };
  if (!keepLevelZero) {
    delete updatedLookupLevels["0"];
  }
  const finalPropName: string[] = [];
  let lookupCols = "";

  Object.keys(updatedLookupLevels).forEach((lookupLevel) => {
    const { lookupColumns, lookupTableName, lookupForeignKey, lookupDisplayColumn, lookupColumnLabel } =
      updatedLookupLevels[lookupLevel];
    if (lookupColumns?.length) {
      lookupCols = lookupDisplayColumn ? lookupDisplayColumn : lookupColumns[0];
      allLookupCols.push(...lookupCols);
      finalPropName.push(lookupColumnLabel || lookupForeignKey || lookupTableName || "");
    } else {
      finalPropName.push(lookupColumnLabel || lookupForeignKey || lookupTableName || "");
    }
  });
  //Handle nested lookups
  return { lookupCols, finalPropName, allLookupCols };
};

export const getColumnValueFromRowData = ({
  row,
  col,
  colOptions,
  view,
  extendedSchema,
  recordTypesData,
  isAdminUser,
  basePageTableName
}: {
  row?: RecordItem;
  col: TableColumnType;
  colOptions: GridViewColumn[];
  view?: ViewOption;
  extendedSchema?: ExtendedSchema;
  recordTypesData?: ApiRecordType[];
  isAdminUser?: boolean;
  basePageTableName?: string;
}) => {
  // if no row data is present return;
  if (!row) return;
  try {
    if (col.isLookup) {
      const results = readLookupFieldsForCellGroups(col, row, extendedSchema, recordTypesData, basePageTableName);
      if (Array.isArray(results)) return results;
      if (typeof results === "string") return results;

      if (col.type === CellType.JSON) {
        return results;
      }

      return results ? [results] : [];
    }

    if (col.isFormula) {
      if (col.formula) {
        if (isEmpty(row) || !colOptions?.length) return null;
        let formula = col.formula;
        formula = parseFormulaToValidExpression(colOptions, formula);
        // use percent value for percent fields while multiplying
        const parseToPercent = formula.includes("*");

        const percentFields = parseToPercent
          ? colOptions.filter((col) => col.type === CellType.PERCENT).map((col) => col.name)
          : [];
        const finalRow = row?.originalRow || row;
        Object.entries(finalRow as RecordItem).forEach(([name, value]) => {
          const divider = percentFields.includes(name) ? 100 : 1;
          const finalVal =
            typeof value === "string"
              ? value.includes(".")
                ? parseFloat(parseFloat(value).toFixed(3))
                : parseInt(value)
              : value;

          if (isNaN(finalVal) || Array.isArray(finalVal)) {
            return;
          }
          const num = (finalVal / divider).toString();
          formula = formula.replaceAll(`{${name}}`, num);
        });
        if (formula.includes("NaN")) return null;

        return mathEvaluator.eval(formula);
      }
      return "Missing formula";
    }

    if (col.type === CellType.ADDRESS) {
      return {
        address1: row["address1"],
        address2: row["address2"],
        city: row["city"],
        state: row["state"],
        zip: row["zip"]
      };
    }

    if (col.type === CellType.COORDINATE) {
      return {
        latitude: col.cellConfig?.latitude_coordinate_column ? row[col.cellConfig?.latitude_coordinate_column] : null,
        longtiude: col.cellConfig?.longitude_coordinate_column ? row[col.cellConfig?.longitude_coordinate_column] : null
      };
    }

    if (col.type === CellType.GENERIC_CELL) {
      const val = getGenericCellValuesFromRecord({
        column: col,
        recordData: row,
        columns: colOptions,
        view,
        extendedSchema,
        recordTypesData
      });
      return val;
    }

    if (col.type === CellType.BEFORE_AFTER) {
      const val = getBeforeAfterCellValuesFromRecord({
        column: col,
        recordData: row,
        columns: colOptions,
        view,
        extendedSchema,
        recordTypesData
      });
      return val;
    }

    if (col.type === CellType.BUTTON && col.cellConfig?.buttonHandlerName) {
      if (col.cellConfig?.isLink) {
        return typeof row[col.cellConfig.buttonHandlerName] === "function"
          ? row[col.cellConfig.buttonHandlerName](row)
          : row[col.cellConfig.buttonHandlerName];
      } else {
        return row[col.cellConfig.buttonHandlerName];
      }
    }
    return row[col.name];
  } catch (err) {
    console.log("@@ Error displaying cell", err, isAdminUser, view);

    return isAdminUser ? { error: err } : view === ViewOption.GRID ? "-" : null;
  }
};

/**
 *
 * @param input - fields required to create final input values map
 * @param input.columns - columns to be used for filtering data for input
 * @param input.formFields - record fields new value selected
 * @param input.extendedSchema - tables schema used for lookup fields
 * @param input.tableName - table name for which input is to be created
 * @param input.recordData - the record current values
 * @param input.joinTableRelationInfo - map of joinTable relation fields
 * @param input.isJoinTable - if main table is join table
 * @param input.recordTypesData - record types data
 * @param input.skipDefaultValueSet - if default value should be skipped
 * @returns {RecordItem | undefined} - final InputValues map
 */
export const constructInputForTableRecord = ({
  columns,
  formFields,
  extendedSchema,
  tableName,
  recordData,
  joinTableRelationInfo,
  isJoinTable,
  recordTypesData,
  skipDefaultValueSet,
  inCustomView,
  source,
  logInfo
}: FormTableRecordInputType): RecordItem | undefined => {
  if (!columns.length || isEmpty(formFields) || !tableName || isEmpty(extendedSchema)) {
    return;
  }

  let finalInput: RecordItem = {};
  if (isJoinTable) {
    Object.keys(formFields).forEach((colId: string) => {
      // current selected value for the column
      const column = columns.find((col) => col.id === colId);
      if (!column || column.isStatic) {
        return;
      }

      const joinTableRelation = joinTableRelationInfo?.find((relation) =>
        relation.columns?.some((col) => col.id === column.id)
      );

      // if formField is for relation table
      if (joinTableRelation) {
        // create join tableInput
        if (!finalInput[FORM_INPUT_KEYS.JOIN_TABLE_INPUT]) {
          finalInput[FORM_INPUT_KEYS.JOIN_TABLE_INPUT] = {};
        }
        if (!finalInput[FORM_INPUT_KEYS.JOIN_TABLE_INPUT][joinTableRelation.relationField]) {
          finalInput[FORM_INPUT_KEYS.JOIN_TABLE_INPUT][joinTableRelation.relationField] = {};
        }

        const columnInputValue = getColumnInput({
          column,
          tableName: joinTableRelation.tableName,
          formValue: formFields[column.id],
          extendedSchema,
          recordData: recordData?.[joinTableRelation.relationField],
          inCustomView,
          source: `${source}: isjoinTableRelation Check`,
          logInfo
        });

        if (
          columnInputValue &&
          finalInput[FORM_INPUT_KEYS.JOIN_TABLE_INPUT][joinTableRelation.relationField]?.[
            FORM_INPUT_KEYS.JOIN_TABLE_INPUT
          ]
        ) {
          columnInputValue[FORM_INPUT_KEYS.JOIN_TABLE_INPUT] = {
            ...finalInput[FORM_INPUT_KEYS.JOIN_TABLE_INPUT][joinTableRelation.relationField]?.[
              FORM_INPUT_KEYS.JOIN_TABLE_INPUT
            ],
            ...columnInputValue[FORM_INPUT_KEYS.JOIN_TABLE_INPUT]
          };
        }

        if (
          columnInputValue &&
          finalInput[FORM_INPUT_KEYS.JOIN_TABLE_INPUT][joinTableRelation.relationField]?.[FORM_INPUT_KEYS.FILES]
        ) {
          columnInputValue[FORM_INPUT_KEYS.FILES] = {
            ...finalInput[FORM_INPUT_KEYS.JOIN_TABLE_INPUT][joinTableRelation.relationField]?.[FORM_INPUT_KEYS.FILES],
            ...columnInputValue[FORM_INPUT_KEYS.FILES]
          };
        }
        finalInput[FORM_INPUT_KEYS.JOIN_TABLE_INPUT][joinTableRelation.relationField] = {
          ...finalInput[FORM_INPUT_KEYS.JOIN_TABLE_INPUT][joinTableRelation.relationField],
          ...columnInputValue,
          joinTableInput: {
            ...columnInputValue?.["joinTableInput"],
            ...finalInput[FORM_INPUT_KEYS.JOIN_TABLE_INPUT][joinTableRelation.relationField]["joinTableInput"],
            joinTableLookupNameToTableMap: {
              ...finalInput[FORM_INPUT_KEYS.JOIN_TABLE_INPUT][joinTableRelation.relationField]?.["joinTableInput"]
                ?.joinTableLookupNameToTableMap,
              ...columnInputValue?.["joinTableInput"]?.["joinTableLookupNameToTableMap"]
            }
          }
        };
      } else {
        const columnInputValue = getColumnInput({
          column,
          tableName,
          formValue: formFields[column.id],
          extendedSchema,
          recordData: recordData,
          inCustomView,
          source: `${source}: isjoinTable Check`,
          logInfo
        });
        const { joinTableFileTagInput, joinTableInput, ...rest } = columnInputValue || {};

        finalInput = {
          ...finalInput,
          ...rest
        };
        if (joinTableInput) {
          const { joinTableLookupNameToTableMap: joinTableLookupNameToTableMap2, ...joinTableInputRest } =
            joinTableInput || {};
          finalInput = {
            ...finalInput,
            joinTableInput: {
              ...finalInput.joinTableInput,
              ...joinTableInputRest,
              joinTableLookupNameToTableMap: {
                ...finalInput.joinTableInput?.joinTableLookupNameToTableMap,
                ...joinTableLookupNameToTableMap2
              }
            }
          };
        }

        if (joinTableFileTagInput) {
          const { joinTableLookupNameToTableMap, ...fileTagInput } = joinTableFileTagInput || {};
          finalInput = {
            ...finalInput,
            joinTableFileTagInput: {
              ...finalInput.joinTableFileTagInput,
              ...fileTagInput,
              joinTableLookupNameToTableMap: {
                ...finalInput.joinTableFileTagInput?.joinTableLookupNameToTableMap,
                ...joinTableLookupNameToTableMap
              }
            }
          };
        }
      }
    });
  } else {
    Object.keys(formFields).forEach((colId: string) => {
      // current selected value for the column
      const column = columns.find((col) => col.id === colId);
      if (!column || (column.isFormula && !column.name)) {
        return;
      }

      const columnInputValue = getColumnInput({
        column,
        tableName,
        formValue: formFields[column.id],
        extendedSchema,
        recordData: recordData,
        recordTypesData,
        skipDefaultValueSet,
        inCustomView,
        source: `${source}: isJoinTable Check else section`,
        logInfo
      });

      if (columnInputValue) {
        const { joinTableInput, files, ...rest } = columnInputValue;

        finalInput = {
          ...finalInput,
          ...rest
        };

        if (files) {
          finalInput[FORM_INPUT_KEYS.FILES] = {
            ...finalInput[FORM_INPUT_KEYS.FILES],
            ...files
          };
        }
        if (joinTableInput) {
          const { files: joinTableFiles, joinTableLookupNameToTableMap, ...joinInputFields } = joinTableInput;
          const finalJoinTableInput = { ...finalInput[FORM_INPUT_KEYS.JOIN_TABLE_INPUT], ...joinInputFields };
          if (joinTableFiles) {
            finalJoinTableInput[FORM_INPUT_KEYS.FILES] = {
              ...finalJoinTableInput[FORM_INPUT_KEYS.FILES],
              ...joinTableFiles
            };
          }

          if (!isEmpty(joinTableLookupNameToTableMap)) {
            finalJoinTableInput.joinTableLookupNameToTableMap = {
              ...(finalJoinTableInput.joinTableLookupNameToTableMap || {}),
              ...joinTableLookupNameToTableMap
            };
          }

          finalInput[FORM_INPUT_KEYS.JOIN_TABLE_INPUT] = {
            ...finalJoinTableInput
          };
        }
      }
    });
  }

  return omitBy(finalInput, isUndefined);
};

const getColumnInput = ({
  column,
  formValue,
  tableName,
  extendedSchema,
  recordData,
  recordTypesData,
  skipDefaultValueSet,
  inCustomView,
  source,
  logInfo
}: {
  column: TableColumnType;
  tableName: string;
  formValue: RecordItem;
  extendedSchema: { [key: string]: TableSchema };
  recordData?: RecordItem;
  recordTypesData?: ApiRecordType[];
  skipDefaultValueSet?: boolean;
  inCustomView?: boolean;
  source: string;
  logInfo: ({ source, additionalInfo }: InfoLoggerProps) => void;
}) => {
  const tableProps = extendedSchema?.[tableName]?.properties;
  const tableFKeys = extendedSchema?.[tableName]?.fk;

  const { name, isLookup, defaultValues } = column;
  const finalInput: RecordItem = {};
  if (!isLookup) {
    const formEntry: CellGroupValueInput = formValue;
    let finalValue = defaultValues?.value && !formEntry && !skipDefaultValueSet ? defaultValues.value : formEntry;
    let isSpecialDefault = false;
    if (SPECIAL_DEFAULTS_VALUES.includes((defaultValues?.value as string) || "")) {
      isSpecialDefault = true;
    }

    // Check if column is foreign key but not Select
    const isForeignKey = tableFKeys?.find((fKeyEntry: FKey) => fKeyEntry.attributeId === name);
    if (!isSpecialDefault) {
      if ((SelectCellTypes.includes(column.type) || column.isSelect) && formEntry && !column.isTextArray) {
        // This is part of a composite key
        if (column.isMultiple && Array.isArray(formEntry)) {
          // Adding single value as most common use case is 2 column composite key
          finalInput[FORM_INPUT_KEYS.MULTI_CREATE] = name; // Only add column name here as we add the formEntry to finalInput below
        } else {
          // Don't reset default value only if there is no existing entry
          if (name && !(defaultValues?.value && !formEntry)) {
            finalValue =
              isForeignKey || column.dbType?.type !== "string"
                ? (formEntry as CellGroupValueType).optionData?.id ||
                  (formEntry as CellGroupValueType).record?.id ||
                  (formEntry as CellGroupValueType).id
                : isColumnTextWithOptions(column)
                  ? typeof formEntry === "string" // in case of inline row creation we auto populate the col value based on the group selected
                    ? formEntry
                    : (formEntry as CellGroupValueType).title
                  : (formEntry as CellGroupValueType)?.value;
          }
        }
      } else {
        if (tableProps[name]?.type === "boolean") {
          finalValue = formEntry;
        }
        if (tableProps[name]?.type === "number" || tableProps[name]?.type === "integer") {
          finalValue = /[0-9]/.test(formEntry as string)
            ? tableProps[name]?.type === "number"
              ? parseFloat(parseFloat(formEntry).toFixed(3))
              : parseInt(formEntry as string, 10)
            : null;
        }
      }
    }

    if (isForeignKey && formEntry?.id) {
      finalInput[name] = formEntry.id;
    } else {
      finalInput[name] = finalValue;
    }

    if (!isEmpty(recordData)) {
      const oldValue = recordData[name];
      if (!isEmpty(oldValue) && isEmpty(finalValue)) {
        finalInput[name] = EMPTY_FIELD;
      }
    }

    if (column.type === CellType.JSON) {
      finalInput["json"] = finalValue;
    }

    // special column input formating for Basic type CellAddress column
    if (column.type === CellType.ADDRESS) {
      finalInput["address1"] = finalValue?.address1;
      finalInput["address2"] = finalValue?.address2;
      finalInput["city"] = finalValue?.city;
      finalInput["state"] = finalValue?.state;
      finalInput["zip"] = finalValue?.zip;
      if (!isEmpty(recordData)) {
        const oldValue = recordData[name];
        if (oldValue.address1 && !finalValue?.address1) {
          finalInput["address1"] = EMPTY_FIELD;
        }
        if (oldValue.address2 && !finalValue?.address2) {
          finalInput["address2"] = EMPTY_FIELD;
        }
        if (oldValue.city && !finalValue?.city) {
          finalInput["city"] = EMPTY_FIELD;
        }
        if (oldValue.state && !finalValue?.state) {
          finalInput["state"] = EMPTY_FIELD;
        }
        if (oldValue.zip && !finalValue?.zip) {
          finalInput["zip"] = EMPTY_FIELD;
        }
      }
    }
  } else {
    const formEntry: RecordItem = formValue;

    // Remove empty invalid updates
    if (
      !formEntry &&
      !column?.views?.[ViewOption.FORM]?.isHidden &&
      !(column.type === CellType.BOOLEAN && formEntry === false) &&
      !(TEXT_TYPE_CELLS.includes(column.type) && formEntry === "") &&
      !(formEntry === 0 && column.type === CellType.CURRENCY)
    ) {
      return;
    }

    const lookupPath = column.lookupPath?.[0];
    let imageLookupPath;

    const matchingRecordType = recordTypesData?.find((recordType) => recordType?.type === column.type);
    if (matchingRecordType && matchingRecordType?.image_column?.id) {
      imageLookupPath = getImageColumnForRecordType(matchingRecordType, column, extendedSchema)?.lookupPath?.[0];
    }
    if (!lookupPath) {
      console.log("@Error no lookup path found");
      return;
    }
    const isFilesTag = isColumnFileTag(column);
    const joinTableLookupNameToTableMap: RecordItem = {}; // Manages mapping of lookupColumnLabel to table name
    if (isFilesTag) {
      // We need to create files_tags entries with the selected tags
      // Remove any existing files_tags entries
      const compositePrimaryKey = extendedSchema["files_tags"].compositePk;
      const tagsPK = compositePrimaryKey?.filter((pk) => pk.table === "tags")?.[0];
      if (!tagsPK || !recordData) {
        return {};
      }
      if (!finalInput.joinTableFileTagInput) {
        finalInput.joinTableFileTagInput = {};
      }
      if (Array.isArray(formEntry)) {
        const input: RecordItem[] = [];

        const fileRecord = getFileIdFromRecordBasedOnLoookupPath(recordData, column);
        const finalFileRecordId = tableName === "files" ? recordData.id : fileRecord?.id;
        if (!finalFileRecordId) {
          return {};
        }

        joinTableLookupNameToTableMap[lookupPath.lookupColumnLabel || lookupPath.lookupTableName] = "files_tags";
        formEntry.forEach((tagEntry) => {
          input.push({
            [tagsPK.attributeId]: tagEntry.optionData?.id || tagEntry.record?.id || tagEntry.id,
            file_id: finalFileRecordId
          });
        });
        finalInput["joinTableFileTagInput"][lookupPath.lookupColumnLabel || lookupPath.lookupTableName] = input;
      } else {
        joinTableLookupNameToTableMap[lookupPath.lookupColumnLabel || lookupPath.lookupTableName] = "files_tags";

        const fileRecord = getFileIdFromRecordBasedOnLoookupPath(recordData, column);
        // fileRecord?.record?.id comes from bulk edit where we add form data
        // into record for rollup and formula columns
        const finalFileRecordId = tableName === "files" ? recordData.id : fileRecord?.id || fileRecord?.record?.id;
        if (!finalFileRecordId) {
          return {};
        }
        finalInput["joinTableFileTagInput"][lookupPath.lookupColumnLabel || lookupPath.lookupTableName] = isEmpty(
          formEntry
        )
          ? EMPTY_FIELD
          : [
              {
                [tagsPK.attributeId]: formEntry.optionData?.id || formEntry.record?.id || formEntry.id,
                file_id: finalFileRecordId
              }
            ];
      }
      finalInput["joinTableFileTagInput"].joinTableLookupNameToTableMap = joinTableLookupNameToTableMap;
    }

    // if lookup type foreign key
    if (lookupPath.lookupType === LookupTypes.FOREIGN && lookupPath.lookupForeignKey && !isFilesTag) {
      if (column.type === CellType.FILE && !column.cellConfig?.hasAdd) {
        if (!finalInput["files"]) {
          finalInput["files"] = {};
        }
        if (Array.isArray(formEntry)) {
          finalInput.files[lookupPath.lookupForeignKey] = formEntry[0];
        } else if (!Array.isArray(formEntry) && formEntry) {
          finalInput.files[lookupPath.lookupForeignKey] = formEntry;
        } else if (recordData) {
          const oldValue = recordData[lookupPath.lookupColumnLabel || lookupPath.lookupForeignKey];
          if (!isEmpty(oldValue)) {
            finalInput.files[lookupPath.lookupForeignKey] = EMPTY_FIELD;
          }
        }
      } else {
        if (Array.isArray(formEntry) && !column.isTextArray) {
          finalInput[lookupPath.lookupForeignKey] =
            (formEntry[0] as CellGroupValueType)?.optionData?.id ||
            (formEntry[0] as CellGroupValueType)?.record?.id ||
            (formEntry[0] as CellGroupValueType)?.id;
        } else {
          if (TEXT_TYPE_CELLS.includes(column.type) && !column.cellConfig?.isNotTextForeignKey) {
            if (!column.isSelect) {
              // the field is of text type
              const valueCol = lookupPath.lookupDisplayColumn || lookupPath.lookupColumns?.[0];
              if (valueCol) {
                const finalValue =
                  (defaultValues as DefaultValue)?.[valueCol]?.value &&
                  !(formEntry as RecordItem)?.id &&
                  !skipDefaultValueSet
                    ? (defaultValues as DefaultValue)?.[valueCol]?.value === SPECIAL_DEFAULTS.CURRENT_USER_ID &&
                      (defaultValues as DefaultValue)?.[valueCol]?.dbType === "uuid"
                      ? SPECIAL_DEFAULTS.CURRENT_CONTACT_USER_ID
                      : (defaultValues as DefaultValue)?.[valueCol]?.value
                    : formEntry;
                finalInput[lookupPath.lookupForeignKey] = {
                  ...recordData?.[lookupPath.lookupColumnLabel || lookupPath.lookupForeignKey],
                  [valueCol]: finalValue
                };
              }
            } else {
              // Column is text with select options
              const valueCol = lookupPath.lookupDisplayColumn || lookupPath.lookupColumns?.[0];
              if (valueCol) {
                const finalValue =
                  (defaultValues as DefaultValue)?.[valueCol]?.value &&
                  !(formEntry as RecordItem)?.id &&
                  !skipDefaultValueSet
                    ? (defaultValues as DefaultValue)?.[valueCol]?.value === SPECIAL_DEFAULTS.CURRENT_USER_ID &&
                      (defaultValues as DefaultValue)?.[valueCol]?.dbType === "uuid"
                      ? SPECIAL_DEFAULTS.CURRENT_CONTACT_USER_ID
                      : (defaultValues as DefaultValue)?.[valueCol]?.value
                    : column.isTextArray
                      ? formEntry
                      : (formEntry as RecordItem)?.value;
                finalInput[lookupPath.lookupForeignKey] = {
                  ...recordData?.[lookupPath.lookupColumnLabel || lookupPath.lookupForeignKey],
                  [valueCol]: finalValue
                };
              }
            }
          } else if (column.type === CellType.ADDRESS) {
            finalInput[lookupPath.lookupForeignKey] = {
              ...recordData?.[lookupPath.lookupColumnLabel || lookupPath.lookupForeignKey],
              ...formEntry
            };
          } else if (matchingRecordType?.page_id && lookupPath?.isLvl2RecordTypeForeignKey) {
            const finalLookupPath = imageLookupPath || lookupPath;
            const currentRecord =
              recordData?.[finalLookupPath?.lookupColumnLabel || finalLookupPath?.lookupForeignKey || ""];
            if (currentRecord?.id && column?.lookupPath?.["1"]?.lookupForeignKey) {
              // This is an input on a foreign table and will trigger a separate request
              // ONLY works for edit as we need the base record id
              finalInput["foreignTableInput"] = {
                tableName: lookupPath.lookupTableName,
                input: {
                  id: currentRecord.id,
                  [column?.lookupPath?.["1"]?.lookupForeignKey]:
                    (formEntry as CellGroupValueType)?.optionData?.id ||
                    (formEntry as CellGroupValueType)?.record?.id ||
                    (formEntry as CellGroupValueType)?.id
                }
              };
            }
          } else {
            finalInput[lookupPath.lookupForeignKey] =
              (formEntry as CellGroupValueType)?.optionData?.id ||
              (formEntry as CellGroupValueType)?.record?.id ||
              (formEntry as CellGroupValueType)?.id;
            // Only set default if no value present in formEntry
            if (
              (defaultValues as DefaultValue)?.[lookupPath.lookupForeignKey]?.value &&
              !finalInput[lookupPath.lookupForeignKey] &&
              !skipDefaultValueSet
            ) {
              finalInput[lookupPath.lookupForeignKey] =
                (defaultValues as DefaultValue)?.[lookupPath.lookupForeignKey]?.value ===
                  SPECIAL_DEFAULTS.CURRENT_USER_ID &&
                (defaultValues as DefaultValue)?.[lookupPath.lookupForeignKey]?.dbType === "uuid"
                  ? SPECIAL_DEFAULTS.CURRENT_CONTACT_USER_ID
                  : (defaultValues as DefaultValue)?.[lookupPath.lookupForeignKey]?.value;
            }
          }
        }

        if (
          recordData &&
          (recordData[name] ||
            recordData[lookupPath.lookupColumnLabel || lookupPath.lookupForeignKey] ||
            (imageLookupPath?.lookupColumnLabel && recordData[imageLookupPath.lookupColumnLabel])) &&
          (!finalInput[lookupPath.lookupForeignKey] || finalInput[lookupPath.lookupForeignKey] === EMPTY_FIELD) &&
          !lookupPath.isLvl2RecordTypeForeignKey
        ) {
          finalInput[lookupPath.lookupForeignKey] = EMPTY_FIELD;
        }
      }
    }

    // if looks type is join table
    if (lookupPath.lookupType === LookupTypes.JOIN && !isFilesTag) {
      // Handle case where text column on join table is being updated
      // Extend this if other types (boolean, number) are added to join table
      const isColumnForeignKeyOnJoin = isLookupForeignKeyOnJoinTable(column, extendedSchema);
      const isColumnTextOnJoin = isColumnForTextFieldOnJoinTable(column);
      const compositePrimaryKey = extendedSchema[lookupPath.lookupTableName]?.compositePk;
      // if composite primary key exist then only update/create possible on input table
      if (compositePrimaryKey?.length) {
        if (!finalInput.joinTableInput) {
          finalInput.joinTableInput = {};
        }

        if (isColumnForeignKeyOnJoin) {
          if (!isEmpty(formEntry) && !Array.isArray(formEntry)) {
            const foreignKeyColummn = column.lookupPath?.["1"]?.lookupForeignKey;
            // Join table entry already exists
            // Composite primary key with foreign key is expected
            if (foreignKeyColummn) {
              const compositeKeyInput: RecordItem = {};
              compositePrimaryKey.forEach((key) => {
                compositeKeyInput[key.attributeId] = formEntry?.[key.attributeId];
              });
              const input = {
                compositePrimaryKey: compositeKeyInput,
                [foreignKeyColummn]: formEntry?.[foreignKeyColummn]?.id
              };
              finalInput.joinTableInput["joinCompositeKeyUpdate"] = {
                [lookupPath.lookupColumnLabel || lookupPath.lookupTableName]: input
              };

              joinTableLookupNameToTableMap[lookupPath.lookupColumnLabel || lookupPath.lookupTableName] =
                lookupPath.lookupTableName;
            }
          } else if (isEmpty(formEntry) && !isEmpty(defaultValues)) {
            const compositeKeyInput: RecordItem = {};
            compositePrimaryKey.forEach((key) => {
              compositeKeyInput[key.attributeId] = (defaultValues as DefaultValue)?.[key.attributeId]?.value;
            });
            const hasNonCompositeAttributes = Object.keys(defaultValues as DefaultValue).filter((key: string) => {
              return (
                (defaultValues as DefaultValue)?.[key].tableName === lookupPath.lookupTableName &&
                !(defaultValues as DefaultValue)?.[key].isCompositePk
              );
            });
            if (hasNonCompositeAttributes?.length) {
              hasNonCompositeAttributes.forEach((key: string) => {
                compositeKeyInput[key] =
                  (defaultValues as DefaultValue)?.[key]?.value === SPECIAL_DEFAULTS.CURRENT_USER_ID &&
                  (defaultValues as DefaultValue)?.[key]?.dbType === "uuid"
                    ? SPECIAL_DEFAULTS.CURRENT_CONTACT_USER_ID
                    : (defaultValues as DefaultValue)?.[key].value;
              });
            }
            finalInput.joinTableInput = {
              [lookupPath.lookupColumnLabel || lookupPath.lookupTableName]: compositeKeyInput
            };
            joinTableLookupNameToTableMap[lookupPath.lookupColumnLabel || lookupPath.lookupTableName] =
              lookupPath.lookupTableName;
          }
        } else {
          if (!isEmpty(formEntry) && Array.isArray(formEntry)) {
            const input: RecordItem[] = [];

            if (formEntry.length) {
              formEntry?.forEach((entry: RecordItem) => {
                const compositeKeyInput: RecordItem = {};
                compositePrimaryKey.forEach((key) => {
                  // Need to test the side-effects of removing this check
                  if (key.table === "files" && !column.cellConfig?.hasAdd && !inCustomView) {
                    logInfo({
                      source: `${source} - getColumnInput`,
                      additionalInfo: {
                        message:
                          "Check for condition:  key.table === files && !column.cellConfig?.hasAdd && !inCustomView)",
                        compositePrimaryKey,
                        columnId: column.id,
                        pageColumnId: column.pageColumnId,
                        tableName,
                        formEntry
                      }
                    });
                    compositeKeyInput[key.attributeId] = entry;
                  } else if (key.table !== tableName) {
                    compositeKeyInput[key.attributeId] = entry.optionData
                      ? isObject(entry.optionData?.[key.attributeId])
                        ? entry.optionData?.[key.attributeId]?.id
                        : entry.optionData?.[key.attributeId]
                          ? entry.optionData?.[key.attributeId]
                          : entry.optionData?.id
                      : isObject(entry[key.attributeId])
                        ? entry[key.attributeId].id
                        : entry[key.attributeId]
                          ? entry[key.attributeId]
                          : entry.id;

                    if (key.attributeId === "id") {
                      // Special case to read compositeId from record
                      compositeKeyInput[key.attributeId] = entry.record?.compositeId || entry?.compositeId;
                    }
                    if (
                      (defaultValues as DefaultValue)?.[key.attributeId] &&
                      (defaultValues as DefaultValue)?.[key.attributeId]?.tableName === lookupPath.lookupTableName &&
                      !compositeKeyInput[key.attributeId] &&
                      !skipDefaultValueSet
                    ) {
                      compositeKeyInput[key.attributeId] =
                        (defaultValues as DefaultValue)?.[key.attributeId]?.value ===
                          SPECIAL_DEFAULTS.CURRENT_USER_ID &&
                        (defaultValues as DefaultValue)?.[key.attributeId]?.dbType === "uuid"
                          ? SPECIAL_DEFAULTS.CURRENT_CONTACT_USER_ID
                          : (defaultValues as DefaultValue)?.[key.attributeId].value;
                    }
                  } else {
                    compositeKeyInput[key.attributeId] = undefined;
                  }
                });

                if (!isEmpty(omitBy(compositeKeyInput, isUndefined))) {
                  input.push(compositeKeyInput);
                }
              });
            }
            if (column.type === CellType.FILE && !finalInput["joinTableInput"]["files"] && !column.cellConfig?.hasAdd) {
              finalInput["joinTableInput"]["files"] = {};
            }

            joinTableLookupNameToTableMap[lookupPath.lookupColumnLabel || lookupPath.lookupTableName] =
              lookupPath.lookupTableName;
            if (input.length) {
              if (column.type === CellType.FILE && !column.cellConfig?.hasAdd) {
                finalInput["joinTableInput"]["files"][lookupPath.lookupColumnLabel || lookupPath.lookupTableName] =
                  input;
              } else {
                finalInput["joinTableInput"][lookupPath.lookupColumnLabel || lookupPath.lookupTableName] = input;
              }
            } else if (recordData && recordData[lookupPath.lookupColumnLabel || lookupPath.lookupTableName]?.length) {
              if (column.type === CellType.FILE && !column.cellConfig?.hasAdd) {
                finalInput["joinTableInput"]["files"][lookupPath.lookupColumnLabel || lookupPath.lookupTableName] =
                  EMPTY_FIELD;
              } else {
                finalInput["joinTableInput"][lookupPath.lookupColumnLabel || lookupPath.lookupTableName] = EMPTY_FIELD;
              }
            }
          } else if (isEmpty(formEntry) && !isEmpty(defaultValues) && !skipDefaultValueSet) {
            // Case when column is hidden but has default values
            const compositeKeyInput: RecordItem = {};
            compositePrimaryKey.forEach((key) => {
              compositeKeyInput[key.attributeId] = (defaultValues as DefaultValue)?.[key.attributeId]?.value;
            });
            const hasNonCompositeAttributes = Object.keys(defaultValues as DefaultValue).filter((key: string) => {
              return (
                (defaultValues as DefaultValue)?.[key].tableName === lookupPath.lookupTableName &&
                !(defaultValues as DefaultValue)?.[key].isCompositePk
              );
            });
            if (hasNonCompositeAttributes?.length) {
              hasNonCompositeAttributes.forEach((key: string) => {
                compositeKeyInput[key] =
                  (defaultValues as DefaultValue)?.[key]?.value === SPECIAL_DEFAULTS.CURRENT_USER_ID &&
                  (defaultValues as DefaultValue)?.[key]?.dbType === "uuid"
                    ? SPECIAL_DEFAULTS.CURRENT_CONTACT_USER_ID
                    : (defaultValues as DefaultValue)?.[key].value;
              });
            }
            finalInput.joinTableInput = {
              [lookupPath.lookupColumnLabel || lookupPath.lookupTableName]: compositeKeyInput
            };
            joinTableLookupNameToTableMap[lookupPath.lookupColumnLabel || lookupPath.lookupTableName] =
              lookupPath.lookupTableName;
          } else {
            const compositeKeyInput: RecordItem = {};
            if (!finalInput.joinTableInput) {
              finalInput.joinTableInput = {};
            }
            compositePrimaryKey.forEach((key) => {
              if (key.table === "files") {
                compositeKeyInput[key.attributeId] = formEntry[0];
              } else if (key.table !== tableName) {
                compositeKeyInput[key.attributeId] = formEntry.optionData
                  ? isObject(formEntry.optionData?.[key.attributeId])
                    ? formEntry.optionData?.[key.attributeId]?.id
                    : formEntry.optionData?.[key.attributeId]
                      ? formEntry.optionData?.[key.attributeId]
                      : formEntry.optionData?.id
                  : isObject(formEntry[key.attributeId])
                    ? formEntry[key.attributeId].id
                    : formEntry[key.attributeId]
                      ? formEntry[key.attributeId]
                      : formEntry.id;
                if (
                  (defaultValues as DefaultValue)?.[key.attributeId] &&
                  (defaultValues as DefaultValue)?.[key.attributeId]?.tableName === lookupPath.lookupTableName &&
                  (defaultValues as DefaultValue)?.[key.attributeId].value
                ) {
                  compositeKeyInput[key.attributeId] =
                    (defaultValues as DefaultValue)?.[key.attributeId]?.value === SPECIAL_DEFAULTS.CURRENT_USER_ID &&
                    (defaultValues as DefaultValue)?.[key.attributeId]?.dbType === "uuid"
                      ? SPECIAL_DEFAULTS.CURRENT_CONTACT_USER_ID
                      : (defaultValues as DefaultValue)?.[key.attributeId].value;
                }
              } else {
                compositeKeyInput[key.attributeId] = undefined;
              }
            });

            if (column.type === CellType.FILE && !finalInput["joinTableInput"]["files"] && !column.cellConfig?.hasAdd) {
              finalInput["joinTableInput"]["files"] = {};
            }

            if (!isEmpty(omitBy(compositeKeyInput, isUndefined))) {
              joinTableLookupNameToTableMap[lookupPath.lookupColumnLabel || lookupPath.lookupTableName] =
                lookupPath.lookupTableName;
              if (column.type === CellType.FILE && !column.cellConfig?.hasAdd) {
                finalInput["joinTableInput"]["files"][lookupPath.lookupColumnLabel || lookupPath.lookupTableName] =
                  compositeKeyInput;
              } else {
                finalInput["joinTableInput"][lookupPath.lookupColumnLabel || lookupPath.lookupTableName] =
                  compositeKeyInput;
              }
            } else if (recordData && !isEmpty(recordData[lookupPath.lookupColumnLabel || lookupPath.lookupTableName])) {
              joinTableLookupNameToTableMap[lookupPath.lookupColumnLabel || lookupPath.lookupTableName] =
                lookupPath.lookupTableName;
              // Only works for edit and when the join table has an entry already
              if (isColumnTextOnJoin && TEXT_TYPE_CELLS.includes(column.type)) {
                const recordForColumn = recordData[lookupPath.lookupColumnLabel || lookupPath.lookupTableName];
                if (recordForColumn?.[0]?.id && lookupPath.lookupColumns?.[0]) {
                  if (!finalInput["joinTableInput"]["joinUpdateInput"]) {
                    finalInput["joinTableInput"]["joinUpdateInput"] = {};
                  }
                  const columnName = lookupPath.lookupColumns[0];
                  // Actual column value will be added later
                  finalInput.joinTableInput.joinUpdateInput = {
                    ...finalInput.joinTableInput.joinUpdateInput,
                    [lookupPath.lookupColumnLabel || lookupPath.lookupTableName]: {
                      id: recordForColumn[0].id,
                      [columnName]: formEntry
                    }
                  };
                }
              } else if (column.type === CellType.FILE && !column.cellConfig?.hasAdd) {
                finalInput["joinTableInput"]["files"][lookupPath.lookupColumnLabel || lookupPath.lookupTableName] =
                  EMPTY_FIELD;
              } else {
                finalInput["joinTableInput"][lookupPath.lookupColumnLabel || lookupPath.lookupTableName] = EMPTY_FIELD;
              }
            }
          }
        }

        finalInput["joinTableInput"].joinTableLookupNameToTableMap = joinTableLookupNameToTableMap;
      }
    }
  }

  return omitBy(finalInput, isUndefined);
};

export const readLookupFieldsForCellGroupFormInput = (colData: TableColumnType, recordData: RecordItem) => {
  const lookupPath = colData.lookupPath?.[0];

  if (lookupPath?.lookupType === LookupTypes.FOREIGN && lookupPath.lookupForeignKey) {
    return {
      inputValue: recordData[lookupPath.lookupForeignKey],
      displayValue: recordData[lookupPath.lookupForeignKey]
    };
  }

  if (lookupPath?.lookupType === LookupTypes.JOIN && lookupPath.lookupTableName) {
    return {
      inputValue: recordData[lookupPath.lookupTableName],
      displayValue: readLookupFieldsForCellGroups(colData, recordData)
    };
  }

  return null;
};

export enum FORM_INPUT_KEYS {
  JOIN_TABLE_INPUT = "joinTableInput",
  FILES = "files",
  MULTI_CREATE = "multiCreate"
}

export const getTextFromColumn = (col: TableColumnType, data: RecordItem) => {
  if (!col) return "";

  if (col.isLookup) {
    const { lookupCols } = getColumnOptionsLookupPropAndColumnName(col, false, true);

    if (col.type === CellType.ADDRESS) {
      const address = Array.isArray(data) ? data[0] : data;

      if (address) {
        return formatAddress(address);
      }

      return "";
    }
    if (col.type === CellType.DEAL) {
      if (!Array.isArray(data) && data?.address1) {
        return `${data?.address1}, ${data?.address2}, ${data?.city}, ${data?.state}, ${data?.zip}`;
      } else {
        const firstProject = data?.[0];
        if (firstProject?.address1) {
          return `${firstProject?.address1}, ${firstProject?.address2}, ${firstProject?.city}, ${firstProject?.state}, ${firstProject?.zip}`;
        }
      }
    }
    if (col.type === CellType.COMPANY) {
      if (!Array.isArray(data) && data?.name) {
        return data?.name;
      } else if (data?.length) {
        return data.map((company: RecordItem) => company?.name || "").join(", ");
      }
    }
    if (col.type === CellType.GENERIC_CELL) {
      if (Array.isArray(data)) {
        return data
          .map((item: RecordItem) => {
            if (item.layout === GENERIC_CELL_LAYOUTS.COMMA_SEPARATED) {
              const values = Object.keys(item || {})
                .filter((key) => key !== "layout")
                .map((key) => item[key])
                .join(", ");
              return values || "";
            } else {
              return item?.title || "";
            }
          })
          .join(", ");
      } else {
        if (data?.layout === GENERIC_CELL_LAYOUTS.COMMA_SEPARATED) {
          const values = Object.keys(data || {})
            .filter((key) => key !== "layout")
            .map((key) => data[key])
            .join(", ");
          return values || "";
        }
        return data?.title || "";
      }
    }
    if (col.type === CellType.SPACES) {
      if (Array.isArray(data)) {
        return data[0]?.nickname || data[0]?.[lookupCols];
      } else {
        return data?.nickname || data?.[lookupCols] || "";
      }
    }
    if (col.type === CellType.PEOPLE) {
      if (Array.isArray(data)) {
        return data[0]?.full_name || data[0]?.[lookupCols];
      } else {
        return data?.full_name || data?.[lookupCols] || "";
      }
    }
    if (Array.isArray(data)) {
      return data?.map((item: RecordItem) => item?.[lookupCols]).join(", ");
    } else {
      return data?.[lookupCols] || "";
    }
  }

  return data;
};

export const getTextFromColumnWithFieldSelected = (
  col: TableColumnType,
  data?: RecordItem,
  fieldToSelect?: string,
  view?: ViewOption
) => {
  if (!col) return "";

  // This case addresses the use of custom options where no database values are present but title is
  if (view === ViewOption.FORM && !!data?.title && !data?.[fieldToSelect || ""]) {
    return data?.title;
  }

  if ((col.isLookup || col.type === CellType.GENERIC_CELL) && fieldToSelect) {
    if (Array.isArray(data)) {
      return data?.map((item: RecordItem) => item?.[fieldToSelect]).join(", ");
    } else {
      return data?.[fieldToSelect] || "";
    }
  }

  return data;
};

export const getTextFromColumnId = (
  colId: string,
  columns: TableColumnType[] = [],
  recordData: RecordItem = {},
  extendedSchema?: ExtendedSchema,
  recordTypesData?: ApiRecordType[]
) => {
  const titleCol = columns?.find((col) => col.id === colId); // this is used to check for column type
  const titleImageCol = columns?.find((col) => col.id === `image_${col.id}`); // this is used to read data for column where image columns are present

  const finalTitleCol = titleImageCol || titleCol;
  if (!titleCol || !finalTitleCol) {
    return "";
  }

  if (finalTitleCol.isLookup) {
    const { lookupCols } = getColumnOptionsLookupPropAndColumnName(finalTitleCol);
    const data = readLookupFields(finalTitleCol, recordData, extendedSchema, recordTypesData);

    if (titleCol.type === CellType.ADDRESS) {
      const address = Array.isArray(data) ? data[0] : data;

      if (address) {
        return formatAddress(address);
      }

      return "";
    }
    if (titleCol.type === CellType.DEAL) {
      const projectData = getColumnValueFromRowData({ row: recordData, col: finalTitleCol, colOptions: columns });
      if (!Array.isArray(projectData) && projectData?.address1) {
        return `${data?.address1}, ${data?.address2}, ${data?.city}, ${data?.state}, ${data?.zip}`;
      } else {
        const firstProject = projectData?.[0];
        if (firstProject?.address1) {
          return `${firstProject?.address1}, ${firstProject?.address2}, ${firstProject?.city}, ${firstProject?.state}, ${firstProject?.zip}`;
        }
      }
    }
    if (titleCol.type === CellType.COMPANY) {
      if (!Array.isArray(data) && data?.name) {
        return data?.name;
      } else if (data?.length) {
        return data.map((company: RecordItem) => company?.name || "").join(", ");
      }
    }

    if (titleCol.type === CellType.PEOPLE) {
      if (!Array.isArray(data) && data?.full_name) {
        return data?.full_name;
      } else if (data?.length) {
        return data.map((people: RecordItem) => people?.full_name || "").join(", ");
      }
    }

    if (Array.isArray(data)) {
      return data[0]?.[lookupCols] ?? "";
    } else {
      return data?.[lookupCols];
    }
  }
  return getColumnValueFromRowData({ row: recordData, col: finalTitleCol, colOptions: columns });
};

export const getTitleTextFromColumn = ({
  colId,
  columns,
  recordData,
  extendedSchema,
  recordTypesData
}: {
  colId: string;
  columns: TableColumnType[];
  recordData: RecordItem;
  extendedSchema?: ExtendedSchema;
  recordTypesData?: ApiRecordType[];
}) => {
  const titleText = getTextFromColumnId(colId, columns, recordData, extendedSchema, recordTypesData);
  const titleCol = columns?.find((col) => col.id === colId);
  const titleCellType = titleCol?.type;

  if (titleCellType === CellType.DATE) {
    return formatDate(titleText);
  }

  if (titleCellType === CellType.DATETIME) {
    const showRelativeTime = !!titleCol?.cellConfig?.showRelativeTime;
    return formatDateTime(titleText, true, showRelativeTime);
  }

  return titleText || "";
};

export const getMultiTitleText = ({
  config,
  columns,
  recordData,
  extendedSchema,
  multiTitleColumn = "multiTitle",
  recordTypesData
}: {
  config?: TableViewConfig;
  columns: TableColumnType[];
  recordData: RecordItem;
  extendedSchema?: ExtendedSchema;
  multiTitleColumn?: "multiTitle" | "multiSubtitle";
  recordTypesData?: ApiRecordType[];
}) => {
  const multiTitleConfig = config?.additionalConfig?.[multiTitleColumn];
  const finalTitle: string[] = [];

  multiTitleConfig?.forEach((titleCol) => {
    if (titleCol.type === "text") {
      finalTitle.push(titleCol.value);
    } else if (titleCol.type === "column") {
      const titleText =
        getTitleTextFromColumn({
          colId: titleCol.value,
          columns,
          recordData,
          extendedSchema,
          recordTypesData
        }) || "";

      finalTitle.push(titleText);
    }
  });

  return finalTitle.join("");
};

const getAddressFromRecord = ({
  multiTitleConfig,
  columns,
  recordData,
  extendedSchema,
  recordTypesData
}: {
  multiTitleConfig: MultiTitleItem[];
  columns: TableColumnType[];
  recordData: RecordItem;
  extendedSchema?: ExtendedSchema;
  recordTypesData?: ApiRecordType[];
}) => {
  let addressCol = columns.find((col) => {
    if (col?.type === CellType.ADDRESS || col?.type === CellType.DEAL) {
      return !!multiTitleConfig.find((titleCol) => col.id === titleCol.value && titleCol.type === "column");
    }
    return false;
  });

  if (!addressCol) {
    addressCol = columns.find((col) => col?.type === CellType.DEAL);
  }

  if (!addressCol) return;

  const addressData = getColumnValueFromRowData({
    row: recordData,
    col: addressCol,
    colOptions: columns,
    extendedSchema,
    recordTypesData
  });

  return addressData?.[0] as AddressComplete | undefined;
};

export const getTitleInfo = ({
  config,
  columns,
  recordData,
  extendedSchema,
  recordTypesData
}: {
  config?: TableViewConfig;
  columns: TableColumnType[];
  recordData: RecordItem;
  extendedSchema?: ExtendedSchema;
  recordTypesData?: ApiRecordType[];
}) => {
  const multiTitleConfig = config?.additionalConfig?.multiTitle;
  if (!multiTitleConfig) return { title: "" };

  const title = getMultiTitleText({ config, columns, recordData, extendedSchema, recordTypesData });

  const address = getAddressFromRecord({
    multiTitleConfig,
    columns,
    recordData,
    extendedSchema,
    recordTypesData
  });

  return { title, address };
};

export const getBlurUrl = () => {
  const keyStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";

  const triplet = (e1: number, e2: number, e3: number) =>
    keyStr.charAt(e1 >> 2) +
    keyStr.charAt(((e1 & 3) << 4) | (e2 >> 4)) +
    keyStr.charAt(((e2 & 15) << 2) | (e3 >> 6)) +
    keyStr.charAt(e3 & 63);

  const rgbDataURL = (r: number, g: number, b: number) =>
    `data:image/gif;base64,R0lGODlhAQABAPAA${
      triplet(0, r, g) + triplet(b, 255, 255)
    }/yH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==`;

  const blurDataURL = rgbDataURL(153, 153, 153);

  return blurDataURL;
};

export const generateFinalFilters = (tableFiltersOption: TableViewType, columnProperties?: TableColumnProperties) => {
  const finalTableFilters = tableFiltersOption?.filters?.length
    ? tableFiltersOption.filters.map((filter, filterIdx) => {
        if (filter.filterLookupPath) {
          const updatedLookupPath: { [key: string]: LookupEntry } = {};
          Object.keys(filter.filterLookupPath).forEach((lookupLevel) => {
            const {
              lookupTableName = "",
              lookupForeignKey = "",
              lookupColumnLabel = ""
            } = filter.filterLookupPath?.[lookupLevel] || {};

            const tableName = lookupColumnLabel || lookupForeignKey || lookupTableName;
            updatedLookupPath[lookupLevel] = {
              ...filter.filterLookupPath?.[lookupLevel],
              lookupColumnLabel: lookupLevel === "0" ? `${tableName}_filter_${filterIdx}` : ""
            } as LookupEntry;
          });
          return {
            ...filter,
            filterLookupPath: updatedLookupPath
          };
        }

        if (filter.filterField && !filter.column && !filter.filterDbType) {
          return {
            ...filter,
            filterDbType: columnProperties?.[filter.filterField]?.type
          };
        }
        return filter;
      })
    : [];

  return finalTableFilters;
};

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const getColumnByNameAndTableName = (
  columns: TableColumnType[] = [],
  name: string,
  lookupTableName: string,
  lookupForeignKey?: string
) => {
  return columns.find((col) => {
    if (col.isLookup) {
      return Object.keys(col.lookupPath || {}).some((key) =>
        lookupForeignKey
          ? col.lookupPath?.[key]?.lookupForeignKey === lookupForeignKey &&
            col.lookupPath?.[key].lookupTableName === lookupTableName
          : name
            ? col.lookupPath?.[key].lookupColumns?.includes(name) &&
              col.lookupPath?.[key].lookupTableName === lookupTableName
            : col.lookupPath?.[key].lookupTableName === lookupTableName
      );
    }

    return col.name === name;
  });
};

export const getAdditionValueFieldsForOption = ({
  conditionalFormatting,
  columnColors,
  column,
  data,
  columns
}: {
  conditionalFormatting?: ColumnRule[] | null;
  columnColors?: ColumnColorType[] | null;
  column: TableColumnType;
  data: any;
  columns?: TableColumnType[];
}) => {
  const rules = conditionalFormatting?.filter((rule) => rule.ruleConfig?.applyTo === column.id);
  const { lookupCols } = getColumnOptionsLookupPropAndColumnName(column);

  const additionalValueFields = getAdditionValueFieldsFromColumnRules(
    rules,
    {
      [column.id]: column.columnOptionsLookUp && !column.isLookup && isPlainObject(data) ? data?.[lookupCols] : data
    },
    columns || [column]
  );
  const columnColor = columnColors?.find((columnColor) => columnColor.column === column.id);
  if (columnColor?.textColor && !additionalValueFields.textColor) {
    additionalValueFields.textColor = columnColor.textColor;
  }
  return additionalValueFields;
};

export const getAdditionValueFieldsFromColumnRules = (
  rules: ColumnRule[] = [],
  row: RecordItem,
  columns?: TableColumnType[]
) => {
  const additionalValueFields: ColumnCellAdditionalValueFields = {};
  rules?.forEach((rule) => {
    const firstRule = rule.columnRules?.[0];
    const column = columns?.find((col) => col.id === firstRule?.columnId);
    if (!column || !firstRule) return;

    const finalValue = getTextFromColumnWithFieldSelected(column, row[firstRule?.columnId || ""], firstRule.field);
    const passRule = testRule(finalValue, firstRule);
    if (passRule) {
      // add the value to the additional value fields
      const applyToColumn = columns?.find((col) => col.id === rule.ruleConfig?.applyTo);
      if (applyToColumn) {
        // add badgeColor value for celltype column
        if (applyToColumn.type === CellType.BADGE && isBasicOrOnlyLookupColumn(applyToColumn)) {
          additionalValueFields.color = rule.ruleConfig?.badgeColor;
          if (rule.ruleConfig?.icon) {
            additionalValueFields.icon = rule.ruleConfig?.icon;
          }
        }
        if (rule.ruleConfig?.textColor) {
          additionalValueFields.textColor = rule.ruleConfig?.textColor;
        }
      }
    }
  });

  return additionalValueFields;
};

export const getAdditionValueFieldsFromViewConditionalFormattingRules = ({
  rules = [],
  row,
  columns,
  view
}: {
  rules?: ColumnRule[];
  row: RecordItem;
  columns?: TableColumnType[];
  view: ViewOption;
}) => {
  let additionalValueFields: ColumnCellAdditionalValueFields = {};
  rules?.forEach((rule) => {
    const firstRule = rule.columnRules?.[0];
    const column = columns?.find((col) => col.pageColumnId === firstRule?.pageColumnId);
    if (!column || !firstRule) return;

    const finalValue = getTextFromColumnWithFieldSelected(column, row[column.id || ""], firstRule.field);
    const passRule = testRule(finalValue, firstRule);
    if (passRule) {
      // currently only map View has conditional Formatting
      const viewFormattingValues = rule.ruleConfig?.[view];
      additionalValueFields = {
        ...viewFormattingValues
      };
    }
  });

  return additionalValueFields;
};

export const getColumnIdValuesMapFromRowData = ({
  row,
  columnOptions,
  extendedSchema,
  view,
  recordTypesData,
  finalPageTableName
}: {
  row: RecordItem;
  columnOptions: GridViewColumn[];
  view?: ViewOption;
  extendedSchema?: ExtendedSchema;
  recordTypesData?: ApiRecordType[];
  finalPageTableName?: string;
}) => {
  return columnOptions.reduce((acc: RecordItem, col) => {
    acc[col.id] = getColumnValueFromRowData({
      row,
      col,
      colOptions: columnOptions,
      extendedSchema: extendedSchema,
      recordTypesData,
      view,
      basePageTableName: finalPageTableName
    });

    return acc;
  }, {});
};

// return value for a column for algolia data
export const getColumnValueFromRecordForAlgolia = ({
  column,
  record,
  recordTypesData,
  extendedSchema
}: {
  column: TableColumnType;
  record: RecordItem;
  recordTypesData?: ApiRecordType[];
  extendedSchema?: ExtendedSchema;
}) => {
  if (column.isLookup) {
    const data = readLookupFields(column, record, extendedSchema, recordTypesData);
    if (column.type === CellType.TEXT) {
      const { lookupCols } = getColumnOptionsLookupPropAndColumnName(column);
      if (Array.isArray(data)) {
        if (column.isMultiple) {
          return data.map((val) => val[lookupCols]);
        } else {
          return data[0]?.[lookupCols] ?? "";
        }
      } else {
        return data?.[lookupCols] ?? "";
      }
    } else {
      return data;
    }
  } else {
    return record[column.name];
  }
};

// reture compositeKeys From the RecordData
export const getCompositeKeyFromRecord = (recordData: RecordItem, compositePrimaryKey: CompositePKey[]) => {
  const compositeKeyInput: RecordItem = {};
  compositePrimaryKey.forEach((key) => {
    if (recordData?.[key.attributeId]) {
      const value = isObject(recordData?.[key.attributeId])
        ? recordData?.[key.attributeId]?.id
        : recordData?.[key.attributeId];
      if (value) {
        compositeKeyInput[key.attributeId] = value;
      }
    }
  });

  // if all the compositeKey fields are present in input then only return the compositeKeyInput
  if (Object.keys(compositeKeyInput).length === compositePrimaryKey.length) {
    return compositeKeyInput;
  }

  return;
};

export const getDefaultTablePageForColumn = (column: TableColumnType, defaultTablePages?: Page[] | null) => {
  return column?.isLookup && !!column?.lookupPath?.[0]?.lookupTableName
    ? defaultTablePages?.find((page) => {
        let finalLookupTableName = column.lookupPath?.[0]?.lookupTableName;
        // This handles 2 level record type lookups
        if (CellTypesAvailableForUrl.includes(column.type) && !!column?.lookupPath?.["0"]?.isLvl2RecordTypeForeignKey) {
          finalLookupTableName = column.lookupPath?.["1"]?.lookupTableName;
        }
        return (
          page.table_name === finalLookupTableName ||
          page.table_name === CellTypeDefaultPageTablename[column.type as CellTypesWithUrl]
        );
      })
    : undefined;
};
