import isEmpty from "lodash/isEmpty";
import { COLUMN_CONDITIONAL_VISIBILITY } from "components/Admin/utils";
import { getColumnValueFromRowData, getTextFromColumnWithFieldSelected } from "lib/utils";
import { ColumnConditionalRule, ColumnRule, TableColumnType } from "types/baTypes";
import { RecordItem } from "types/common";
import { ApiRecordType } from "types/apiTypes";
import { ViewOption, FILTER_GROUP_TYPE, COLUMN_RULE_OPERATORS, CellType } from "./constants";
import { ExtendedSchema } from "./schema";

type RuleAdditionalValueProps = {
  isRecordView?: boolean;
  inSidebar?: boolean;
  isAdminUser?: boolean;
  basePageTableName?: string;
  extendedSchema?: ExtendedSchema;
  colHeaderTypeMap?: Record<
    string,
    { type: CellType; header: string; columnRules?: RecordItem; column: TableColumnType }
  >;
};

export const testRule = (value: any, rule: ColumnConditionalRule, additionalValueProps?: RuleAdditionalValueProps) => {
  if (rule?.operator === COLUMN_RULE_OPERATORS.WITHIN_RECORD_VIEW) {
    return additionalValueProps?.isRecordView;
  }
  if (rule?.operator === COLUMN_RULE_OPERATORS.WITHIN_SIDEBAR) {
    return additionalValueProps?.inSidebar;
  }
  const isRuleValueBoolean =
    rule.value?.toLocaleLowerCase()?.toString() === "true" || rule.value?.toLocaleLowerCase()?.toString() === "false";
  if (rule.operator === COLUMN_RULE_OPERATORS.CONTAINS) {
    const finalValue = rule.value?.includes(",") ? rule.value.split(",") : [rule.value];
    return Array.isArray(value) ? value?.some((v) => finalValue.includes(v)) : finalValue?.includes(value);
  }
  if (rule.operator === COLUMN_RULE_OPERATORS.NOT_CONTAINS) {
    const finalValue = rule.value?.includes(",") ? rule.value.split(",") : [rule.value];
    return Array.isArray(value) ? !value?.some((v) => finalValue.includes(v)) : !finalValue?.includes(value);
  }
  if (rule.operator === COLUMN_RULE_OPERATORS.EQUALS) {
    if (isRuleValueBoolean && rule.value === "false") {
      // We need to also pass if there is no value
      return value?.toString() === rule.value?.toString() || !value;
    }
    return value?.toString() === rule.value?.toString();
  }
  if (rule.operator === COLUMN_RULE_OPERATORS.NOT_EQUALS) {
    if (isRuleValueBoolean && rule.value === "true") {
      // We need to also pass if there is no value
      return value?.toString() !== rule.value?.toString() || !value;
    }
    return value?.toString() !== rule.value?.toString() || (rule.includeNull && value !== null);
  }
  if (rule.operator === COLUMN_RULE_OPERATORS.GREATER_THAN) {
    return Number(value) > Number(rule.value);
  }
  if (rule.operator === COLUMN_RULE_OPERATORS.LESS_THAN) {
    return Number(value) < Number(rule.value);
  }
  if (rule.operator === COLUMN_RULE_OPERATORS.BLANK) {
    return isEmpty(value);
  }
  if (rule.operator === COLUMN_RULE_OPERATORS.NOT_BLANK) {
    return !isEmpty(value);
  }
  if (rule.operator === COLUMN_RULE_OPERATORS.CHARACTER_LENGTH) {
    return value?.toString()?.length === Number(rule.value);
  }
  if (rule.operator === COLUMN_RULE_OPERATORS.STARTS_WITH) {
    return value?.toString()?.startsWith(rule.value?.toString());
  }
};

export const getFinalVisibilityFromVisibilityRule = ({
  visibilityRule,
  data,
  columnOptions,
  view,
  useDataAsValue,
  recordTypesData,
  columnToCheck,
  additionalValueProps,
  showLog
}: {
  visibilityRule?: ColumnRule;
  data: RecordItem;
  columnOptions: TableColumnType[];
  view?: ViewOption;
  useDataAsValue?: boolean;
  columnToCheck?: TableColumnType;
  recordTypesData?: ApiRecordType[];
  additionalValueProps?: RuleAdditionalValueProps;
  showLog?: boolean;
}) => {
  const isAllRulesPassed = visibilityRule?.columnRules?.reduce(
    (acc: boolean, colRule: ColumnConditionalRule, index) => {
      const isPassed = checkRulePassedForItem(
        colRule,
        data,
        columnToCheck ? [columnToCheck] : columnOptions,
        visibilityRule,
        view,
        useDataAsValue,
        recordTypesData,
        additionalValueProps,
        showLog
      );
      if (index === 0) {
        // first rule
        return isPassed;
      }
      if (colRule.filterGroup === FILTER_GROUP_TYPE.OR) {
        return acc || isPassed;
      } else {
        return acc && isPassed;
      }
    },
    false
  );
  const isHidden =
    visibilityRule?.ruleConfig?.visibility === COLUMN_CONDITIONAL_VISIBILITY.HIDE
      ? isAllRulesPassed
      : !isAllRulesPassed;

  return isHidden ? COLUMN_CONDITIONAL_VISIBILITY.HIDE : COLUMN_CONDITIONAL_VISIBILITY.SHOW;
};

const getVisibilityForColumnRule = (
  ruleCondition: ColumnConditionalRule,
  rule: ColumnRule,
  colToEvaluate?: TableColumnType,
  value?: RecordItem,
  view?: ViewOption,
  additionalValueProps?: RuleAdditionalValueProps,
  showLog?: boolean
) => {
  if (!colToEvaluate || !ruleCondition || !rule) return;
  const valueToEvaluate = getTextFromColumnWithFieldSelected(
    colToEvaluate,
    value,
    view === ViewOption.FORM ? ruleCondition?.field : ruleCondition?.lookupColumnNameField || ruleCondition?.field,
    view
  );
  const passRule = testRule(valueToEvaluate, ruleCondition, additionalValueProps);
  if (passRule) {
    return rule.ruleConfig?.visibility;
  }
  return;
};

export const checkRulePassedForItem = (
  rule: ColumnConditionalRule,
  data: RecordItem,
  columnOptions: TableColumnType[],
  visibilityRule: ColumnRule,
  view?: ViewOption,
  useDataAsValue?: boolean,
  recordTypesData?: ApiRecordType[],
  additionalValueProps?: RuleAdditionalValueProps,
  showLog?: boolean
) => {
  const isDetailMainView = view === ViewOption.DETAIL_MAIN;
  const selectedColKey =
    isDetailMainView && additionalValueProps?.colHeaderTypeMap
      ? Object.keys(additionalValueProps?.colHeaderTypeMap || {}).find(
          (key) => additionalValueProps.colHeaderTypeMap?.[key].column.id === rule?.columnId
        )
      : "";
  if (isDetailMainView && !selectedColKey) {
    return true; // if show the value in detail overview if the column is not present in the view
  }
  const col = isDetailMainView
    ? additionalValueProps?.colHeaderTypeMap?.[selectedColKey || ""].column
    : columnOptions?.find((item) => item.id === rule?.columnId);
  if (!col || !columnOptions?.length) return false;
  const additionalProps = {
    isAdminUser: additionalValueProps?.isAdminUser,
    basePageTableName: additionalValueProps?.basePageTableName,
    extendedSchema: additionalValueProps?.extendedSchema
  };
  const valueToEvaluate = isDetailMainView
    ? data[selectedColKey || ""]
    : useDataAsValue || view === ViewOption.FORM
      ? data
      : view === ViewOption.GRID
        ? data?.[rule?.columnId || ""]
        : getColumnValueFromRowData({ row: data, col, colOptions: columnOptions, recordTypesData, ...additionalProps });
  const finalVisibility = getVisibilityForColumnRule(
    rule,
    visibilityRule,
    col,
    valueToEvaluate,
    view,
    additionalValueProps,
    showLog
  );

  if (finalVisibility === visibilityRule.ruleConfig?.visibility) {
    return true;
  }
  return false;
};
